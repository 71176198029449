import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import useCdStructures from '../../contexts/cdStructures';
import {
  ICreativeDataStructure,
  IStructure,
  SimpleType,
  UiType,
} from '../../models/creativeDataStructure';
import {
  ICategory,
  OperationName,
  buildOperation,
  operations,
} from '../../models/guidelines';
import { buildNestedSetState } from '../../utils/misc';

const CategoriesContext = React.createContext<CategoriesContextType | null>(
  null,
);

type CategoriesProviderProps = PropsWithChildren<{
  categories: ICategory[];
  setCategories: Dispatch<SetStateAction<ICategory[]>>;
}>;
export const CategoriesProvider: React.FC<CategoriesProviderProps> = props => {
  const { children, categories, setCategories } = props;
  const { factorsCdStructures } = useCdStructures();
  const availableCds = factorsCdStructures as ICreativeDataStructure<
    IStructure<UiType<SimpleType>>
  >[];

  const createRule = (category: string) => {
    const categoryIdx = categories.findIndex(c => c.name === category);
    if (categoryIdx === -1) return;
    const setCategory = buildNestedSetState(setCategories, categoryIdx);
    const setRules = buildNestedSetState(setCategory, 'rules');
    const cds = availableCds[0];
    if (!cds) return;
    type Type = typeof cds.structure.type;
    const operationName = Object.keys(
      operations[cds.structure.type],
    )[0] as OperationName<Type>;
    setRules(prev => [
      ...prev,
      {
        cds,
        operation: buildOperation(cds.structure.type, operationName),
        weight: 0,
      },
    ]);
  };
  const deleteCategory = (categoryName: string) => {
    setCategories(prev => prev.filter(c => c.name !== categoryName));
  };

  const value = {
    categories,
    setCategories,
    availableCds,
    createRule,
    deleteCategory,
  };
  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};

export default function useCategories() {
  const context = React.useContext(CategoriesContext);
  if (context === null)
    throw new Error('useCategories must be used within a CategoriesProvider');
  return context;
}

interface CategoriesContextType {
  categories: ICategory[];
  setCategories: Dispatch<SetStateAction<ICategory[]>>;
  availableCds: ICreativeDataStructure<IStructure<UiType<SimpleType>>>[];
  createRule: (categoryName: string) => void;
  deleteCategory: (categoryName: string) => void;
}
