import { FilterList as FilterListIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useMemo, useState } from 'react';
import IInsight from '../../../models/insights';
import { InsightCard } from './Card';
import useInsightCards, {
  InsightCardsProvider,
  InsightProviderProps,
} from './context';
import InsightFocus from './InsightFocus';

const InsightCards: React.FC<InsightProviderProps> = props => {
  return (
    <InsightCardsProvider {...props}>
      <InsightCardsInner />
    </InsightCardsProvider>
  );
};
export default InsightCards;

const InsightCardsInner: React.FC = () => {
  const { insights } = useInsightCards();
  const [activeTab, setActiveTab] = useState<number>(0);

  const positiveInsights = useMemo(
    () => insights.filter(i => i.isPositive),
    [insights],
  );
  const negativeInsights = useMemo(
    () => insights.filter(i => !i.isPositive),
    [insights],
  );

  return (
    <Box>
      <InsightFocus />
      <Tabs
        value={activeTab}
        onChange={(_, newValue: number) => setActiveTab(newValue)}
        centered
        sx={{ position: 'relative' }}
      >
        <Tab label='Positive Insights' />
        <Tab label='Negative Insights' />
        <Box position='absolute' sx={{ right: 0 }}>
          <FilterPopover />
        </Box>
      </Tabs>

      <InsightTabPanel
        value={activeTab}
        index={0}
        insights={positiveInsights}
      />
      <InsightTabPanel
        value={activeTab}
        index={1}
        insights={negativeInsights}
      />
    </Box>
  );
};

function InsightTabPanel(props: {
  index: number;
  value: number;
  insights: IInsight[];
}) {
  const { value, index, insights } = props;
  const [showAll, setShowAll] = useState<boolean>(false);

  const showInsights = useMemo(() => {
    return showAll ? insights : insights.slice(0, 6);
  }, [insights, showAll]);

  return (
    <div role='tabpanel' hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid2 container spacing={2}>
            {showInsights.map(insight => (
              <Grid2 xs={12} md={6} key={insight._id}>
                <InsightCard insight={insight} />
              </Grid2>
            ))}
          </Grid2>
          {insights.length > 6 && (
            <Button onClick={() => setShowAll(!showAll)} sx={{ mt: 2 }}>
              {showAll ? 'Show Less' : 'Show All'}
            </Button>
          )}
        </Box>
      )}
    </div>
  );
}

export const FilterPopover: React.FC = () => {
  const {
    factors,
    metrics,
    selectedFactor,
    selectedMetric,
    setSelectedFactor,
    setSelectedMetric,
  } = useInsightCards();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        onClick={e => setAnchorEl(e.currentTarget)}
        aria-describedby='filter-popover'
      >
        <FilterListIcon />
      </IconButton>
      <Popover
        id='filter-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, minWidth: 200 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Factor</InputLabel>
            <Select
              value={selectedFactor}
              label='Factor'
              onChange={e => setSelectedFactor(e.target.value as string)}
            >
              <MenuItem value=''>All Factors</MenuItem>
              {factors.map(factor => (
                <MenuItem key={factor} value={factor}>
                  {factor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Metric</InputLabel>
            <Select
              value={selectedMetric}
              label='Metric'
              onChange={e => setSelectedMetric(e.target.value as string)}
            >
              <MenuItem value=''>All Metrics</MenuItem>
              {metrics.map(metric => (
                <MenuItem key={metric} value={metric}>
                  {metric}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
};
