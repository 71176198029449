import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import CdsDataGrid from './CdsDataGrid';
import CdsManagerProvider, { useCdsManager } from './context';
import { CreateModal, EditModal } from './modals';

const CdsManager: React.FC = () => {
  return (
    <CdsManagerProvider>
      <CreateModal />
      <EditModal />
      <CdsManagerContent />
    </CdsManagerProvider>
  );
};

const CdsManagerContent: React.FC = () => {
  const { setShowCreateModal } = useCdsManager();

  return (
    <Stack height='100%' spacing={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Structure Manager</Typography>
      </Stack>
      <Box flexGrow={1} minHeight={0}>
        <Button
          style={{ margin: '20px 0px' }}
          variant='contained'
          color='primary'
          onClick={() => setShowCreateModal(true)}
        >
          New Creative Data
        </Button>
        <CdsDataGrid />
      </Box>
    </Stack>
  );
};
export default CdsManager;
