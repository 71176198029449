import { Box } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SidePanel from './components/Layout/SidePanel';
import { AuthProvider } from './contexts/auth';
import { CdStructuresProvider } from './contexts/cdStructures';
import { GlobalProvider } from './contexts/global';
import { GuidelinesProvider } from './contexts/guidelines';
import AISearch from './features/AISearch';
import { AuthCheckpoint } from './features/Auth';
import CalculatedFields from './features/CalculatedFields/Index';
import CdsManager from './features/CdsManager';
import Dashboard from './features/Dashboard';
import Experiments from './features/Experiments';
import GuidelinesManagers from './features/GuidelinesManagers';
import BookmarkedInsights from './features/Insights/Bookmarked';
import InsightSpotter from './features/Insights/Spotter';
import Library from './features/Library';
import Moments from './features/Moments';
import Preflight from './features/Preflight';
import UsersManager from './features/UsersManager';
import VideoDashboard from './features/VideoDashboard';

const panelWidth = '240px';

function App() {
  return (
    <BrowserRouter>
      {/* TODO add mui custom ThemeProvider here */}
      <AuthProvider>
        <AuthCheckpoint>
          <GlobalProvider>
            <CdStructuresProvider>
              <GuidelinesProvider>
                <AppLayout />
              </GuidelinesProvider>
            </CdStructuresProvider>
          </GlobalProvider>
        </AuthCheckpoint>
      </AuthProvider>
    </BrowserRouter>
  );
}

const AppLayout: React.FC = () => {
  return (
    <>
      <SidePanel width={panelWidth} />
      <Box
        component='main'
        sx={{
          height: '100%',
          bgcolor: 'background.default',
          padding: '0px 30px',
          marginLeft: panelWidth,
          overflow: 'auto',
        }}
      >
        <AppRoutes />
      </Box>
    </>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Navigate to='/dashboard' />} />
      <Route path='dashboard' element={<Dashboard />} />
      <Route path='library' element={<Library />} />
      <Route path='videos/:id' element={<VideoDashboard />} />
      <Route path='InsightSpotter' element={<InsightSpotter />} />
      <Route path='Moments' element={<Moments />} />
      <Route path='Experiments/*' element={<Experiments />} />
      {/* <Route path='Opera' element={<Opera />} /> */}
      <Route path='AISearch' element={<AISearch />} />
      <Route path='preflights/*' element={<Preflight />} />
      <Route path='BookmarkedInsights' element={<BookmarkedInsights />} />
      <Route path='admin/*' element={<AdminRoutes />} />
      <Route path='404' element={<Box>Not found</Box>} />
      <Route path='*' element={<Navigate to='/404' />} />
    </Routes>
  );
};

const AdminRoutes: React.FC = () => {
  return (
    <AuthCheckpoint role={'ADMIN'}>
      <Routes>
        <Route path='structures' element={<CdsManager />} />
        <Route path='calculatedFields' element={<CalculatedFields />} />
        <Route path='guidelines/*' element={<GuidelinesManagers />} />
        <Route path='users/*' element={<UsersManager />} />
      </Routes>
    </AuthCheckpoint>
  );
};

export default App;
