import { Box, Chip, Grid, Stack } from '@mui/material';
import React from 'react';
import IInsight from '../../../models/insights';
import BookmarkButton from './BookmarkButton';
import InsightIcon from './InsightIcon';
import useInsightCards from './context';

interface InsightCardProps {
  insight: IInsight;
}
export const InsightCard: React.FC<InsightCardProps> = props => {
  const { insight } = props;
  const { setSelectedInsight } = useInsightCards();
  return (
    <Stack
      direction='row'
      p={2}
      spacing={2}
      key={insight._id}
      onClick={() => setSelectedInsight(insight)}
      sx={{
        cursor: 'pointer',
        boxShadow: '0 0 20px 0 #00000017',
        borderRadius: '10px',
      }}
    >
      <InsightIcon isPositive={insight.isPositive} />
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs>
          <div dangerouslySetInnerHTML={{ __html: insight.content }} />
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              {insight.tags.map((tag: string, tagIndex: number) => (
                <Grid item key={tagIndex}>
                  <Chip label={tag} size='small' />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <BookmarkButton insight={insight} />
        </Grid>
      </Grid>
    </Stack>
  );
};
