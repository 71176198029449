import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { Box, Button } from '@mui/material';
import logo from '../../assets/logo.svg';
import { useAuthentication } from '../../contexts/auth';
export const Login: React.FC = () => {
  const { login, loading, error } = useAuthentication();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        gap: 3,
        bgcolor: '#fff',
      }}
    >
      <img
        src={logo}
        alt='Creative One Logo'
        width={250}
        style={{ marginBottom: '5rem' }}
      />

      <Button
        onClick={login}
        disabled={loading}
        variant='contained'
        startIcon={<MicrosoftIcon />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
          padding: '12px 24px',
          textTransform: 'none',
          width: '300px',
          '&:hover': {
            backgroundColor: '#f8f8f8',
          },
        }}
      >
        Sign in with L'Oréal
      </Button>

      {error && <Box color='error.main'>{error}</Box>}

      <Button
        href='#request-access'
        color='primary'
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          color: '#666',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
      >
        Request Access
      </Button>
    </Box>
  );
};
