import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import IInsight from '../../models/insights';
import InsightCards from './Cards';

const BookmarkedInsights: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const [bookmarkedInsights, setBookmarkedInsights] = useState<IInsight[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.insights
      .getBookmarkedInsights()
      .then(setBookmarkedInsights)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [api]);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (bookmarkedInsights.length === 0) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant='h6'>No bookmarked insights yet.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h4' sx={{ mb: 2 }}>
        Bookmarked Insights
      </Typography>
      <InsightCards
        insights={bookmarkedInsights}
        setInsights={setBookmarkedInsights}
      />
    </Box>
  );
};

export default BookmarkedInsights;
