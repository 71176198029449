import negativeInsight from '../../../assets/negative-insight.svg';
import positiveInsight from '../../../assets/positive-insight.svg';

const InsightIcon: React.FC<{ isPositive: boolean }> = ({ isPositive }) => {
  return (
    <img
      src={isPositive ? positiveInsight : negativeInsight}
      alt={isPositive ? 'Positive insight' : 'Negative insight'}
    />
  );
};
export default InsightIcon;
