import {
  Autocomplete,
  Box,
  Button,
  Grid,
  LinearProgress,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import FileDropzone from '../../components/FileDropzone';
import NormalizedSelect, {
  MultipleNormalizedSelect,
} from '../../components/NormalizedSelect';
import { useAuthenticatedUser } from '../../contexts/auth';
import { useBrands } from '../../contexts/global';
import {
  CreatePreflightVideo,
  IPreflightVideo,
  perfKeys,
  platforms,
} from '../../models';
import { ApiError } from '../../services/api';
import { buildNestedSetState } from '../../utils/misc';

const NewPreflight: React.FC<{
  postCreate: (preflight: IPreflightVideo) => void;
}> = ({ postCreate }) => {
  const { api } = useAuthenticatedUser();
  const [file, setFile] = useState<File | null>(null);
  const [video, setVideo] = useState<CreatePreflightVideo>({
    metadata: {
      source: 'internal',
      brand: '',
      platform: '' as any,
      country: '',
      campaign: '',
      name: '',
      objectives: [],
    },
  });
  const [filepath, setFilepath] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const { brands } = useBrands();
  const [availableCountries, setAvailableCountries] = useState<string[]>([]);
  useEffect(() => {
    api.videos.countries().then(setAvailableCountries);
  }, [api]);
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      api.preflight
        .create({ ...video, filepath })
        .then(postCreate)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [api, video, filepath, postCreate],
  );
  const setMetadata = buildNestedSetState(setVideo, 'metadata');
  const setName = buildNestedSetState(setMetadata, 'name');
  const setBrand = buildNestedSetState(setMetadata, 'brand');
  const setPlatform = buildNestedSetState(setMetadata, 'platform');
  const setCountry = buildNestedSetState(setMetadata, 'country');
  const setCampaignName = buildNestedSetState(setMetadata, 'campaign');
  const setObjectives = buildNestedSetState(setMetadata, 'objectives');
  const valid =
    video.metadata.brand &&
    video.metadata.platform &&
    video.metadata.country &&
    video.metadata.campaign &&
    video.metadata.name &&
    video.metadata.objectives.length &&
    filepath;

  const onFileSelect = (file: File) => {
    setFile(file);
    api.preflight
      .upload(file, ({ progress }) => {
        if (progress) setProgress(p => (p < progress ? progress * 100 : p));
      })
      .then(({ path }) => {
        setFilepath(path);
        setProgress(100);
      });
  };

  useEffect(() => {
    setFilepath('');
    setProgress(0);
  }, [file]);

  return (
    <Stack
      spacing={2}
      component='form'
      onSubmit={handleSubmit}
      alignItems='center'
    >
      <FileDropzone
        onFileSelect={onFileSelect}
        accept={['video/*']}
        file={file}
        onError={() => setFile(null)}
        height={150}
        maxFileSize={50 * 1024 * 1024} // 50 MB
      />
      <LinearProgress
        variant='determinate'
        value={progress}
        sx={{ width: '100%', height: 5, borderRadius: 1 }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Name'
            value={video.metadata.name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            inputValue={video.metadata.brand}
            onInputChange={(_, value) => setBrand(value)}
            options={brands.map(b => b.BrandName)}
            renderInput={params => <TextField {...params} label={'Brand'} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NormalizedSelect
            label='Platform'
            variant='outlined'
            value={video.metadata.platform}
            onChange={setPlatform}
            options={platforms}
            width='100%'
            noAll
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            inputValue={video.metadata.country}
            onInputChange={(_, value) => setCountry(value)}
            options={availableCountries}
            renderInput={params => <TextField {...params} label={'Country'} />}
            freeSolo
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Campaign Name'
            value={video.metadata.campaign}
            onChange={e => setCampaignName(e.target.value)}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultipleNormalizedSelect
            label='Objectives'
            value={video.metadata.objectives}
            onChange={setObjectives}
            options={perfKeys}
            variant='outlined'
            width='100%'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledButton
            variant='contained'
            color='secondary'
            sx={{
              color: 'black',
              bgcolor: 'white',
            }}
            fullWidth
          >
            Cancel
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledButton
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              color: 'white',
              bgcolor: 'black',
            }}
            disabled={!valid || loading}
            fullWidth
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
      ></Box>
      <ApiErrorComponent error={error} />
    </Stack>
  );
};
export default NewPreflight;

const StyledButton = styled(Button)({
  px: 2,
  borderRadius: '10px',
  textTransform: 'none',
});
