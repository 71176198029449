import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IGuidelineScore } from '../../models/guidelines';
import GuidelinesScore from '../GuidelinesScore';
import useVideoDashboard from './context';

const GuidelinesScores: React.FC<{ variant?: 'default' | 'small' }> = ({
  variant,
}) => {
  return (
    <Stack
      flexDirection={'row'}
      gap={5}
      height={'100%'}
      style={{ padding: '10px' }}
    >
      <BrandGuidelinesScore variant={variant} />
      <PlatformGuidelinesScore variant={variant} />
    </Stack>
  );
};

export default GuidelinesScores;

const BrandGuidelinesScore: React.FC<{ variant?: 'default' | 'small' }> = ({
  variant,
}) => {
  const { api } = useAuthenticatedUser();
  const { video } = useVideoDashboard();
  const [guidelineScore, setGuidelineScore] = useState<IGuidelineScore>();

  useEffect(() => {
    api.videos.scores
      .brand(video._id)
      .then(setGuidelineScore)
      .catch(handleAxiosError({ print: true }));
  }, [api, video._id]);

  if (!guidelineScore) return null;
  return <GuidelinesScore variant={variant} guidelineScore={guidelineScore} />;
};

const PlatformGuidelinesScore: React.FC<{ variant?: 'default' | 'small' }> = ({
  variant,
}) => {
  const { api } = useAuthenticatedUser();
  const { video } = useVideoDashboard();
  const [guidelineScore, setGuidelineScore] = useState<IGuidelineScore>();

  useEffect(() => {
    api.videos.scores
      .platform(video._id)
      .then(setGuidelineScore)
      .catch(handleAxiosError({ print: true }));
  }, [api, video._id]);

  if (!guidelineScore) return null;
  return (
    <GuidelinesScore
      variant={variant}
      platform={video.metadata.platform}
      guidelineScore={guidelineScore}
    />
  );
};

const handleAxiosError =
  (opt: { print: boolean } = { print: false }) =>
  (error: AxiosError<any>) => {
    const msg = error.response?.data?.error || error.message;
    if (opt.print) console.error(msg);
  };
