import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ICreativeDataStructure } from '../models/creativeDataStructure';
import { useAuthenticatedUser } from './auth';

const CdStructuresContext = createContext<CdStructuresContextType | null>(null);

export const CdStructuresProvider: FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const [cdStructures, setCdStructures] = useState<ICreativeDataStructure[]>(
    [],
  );
  useEffect(() => {
    api.cds.all().then(setCdStructures);
  }, [api]);

  const visibleCdStructures = useMemo(
    () => cdStructures.filter(cds => cds.display),
    [cdStructures],
  );
  const factorsCdStructures = useMemo(
    () => cdStructures.filter(cds => cds.isFactor),
    [cdStructures],
  );
  const dashboardCdStructures = useMemo(
    () => cdStructures.filter(cds => cds.showInDashboard),
    [cdStructures],
  );
  const categories = Array.from(new Set(cdStructures.map(cds => cds.category)));
  const visibleCategories = Array.from(
    new Set(visibleCdStructures.map(cds => cds.category)),
  );

  const createCds = (structure: Omit<ICreativeDataStructure, '_id'>) => {
    return api.cds.create(structure).then(s => {
      setCdStructures(prev => [...prev, s]);
      return s;
    });
  };

  const updateCds = (structure: ICreativeDataStructure) => {
    return api.cds.update(structure).then(s => {
      setCdStructures(prev => prev.map(ps => (ps._id === s._id ? s : ps)));
      return s;
    });
  };

  const deleteCds = (id: string) => {
    return api.cds.delete(id).then(() => {
      setCdStructures(prev => prev.filter(ps => ps._id !== id));
    });
  };

  const value = {
    categories,
    visibleCategories,
    cdStructures,
    visibleCdStructures,
    factorsCdStructures,
    dashboardCdStructures,
    createCds,
    updateCds,
    deleteCds,
  };
  return (
    <CdStructuresContext.Provider value={value}>
      {children}
    </CdStructuresContext.Provider>
  );
};

interface CdStructuresContextType {
  categories: string[];
  visibleCategories: string[];
  cdStructures: ICreativeDataStructure[];
  visibleCdStructures: ICreativeDataStructure[];
  factorsCdStructures: ICreativeDataStructure[];
  dashboardCdStructures: ICreativeDataStructure[];
  createCds: (
    structure: Omit<ICreativeDataStructure, '_id'>,
  ) => Promise<ICreativeDataStructure>;
  updateCds: (
    structure: ICreativeDataStructure,
  ) => Promise<ICreativeDataStructure>;
  deleteCds: (id: string) => Promise<void>;
}

export default function useCdStructures() {
  const context = useContext(CdStructuresContext);
  if (!context)
    throw new Error('useCdStructures must be used within a CdsProvider');
  return context;
}
