import React from 'react';
import { KeyValueItem } from '../../models/creativeData';

interface ColorPaletteProps<K extends React.Key, V extends any> {
  data: KeyValueItem<K, V>[];
  parser?: (value: V) => number;
}

// Helper function to check if a string is a valid color
const isValidColor = (color: string): boolean => {
  const s = new Option().style;
  s.color = color;
  return s.color !== '';
};

// Add this helper function to determine if a color is dark
const isColorDark = (hexColor: string): boolean => {
  // Remove the hash if it exists
  const color = hexColor.replace('#', '');
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Calculate brightness using relative luminance
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
};

const ColorPalette = <K extends React.Key, V extends any>({
  data,
  parser = (v: V) => v as number,
}: ColorPaletteProps<K, V>) => {
  const colors = data
    .filter(item => isValidColor(String(item.key)))
    .map(item => ({
      key: String(item.key),
      value: parser(item.value),
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 10);

  const total = colors.reduce((sum, item) => sum + item.value, 0);

  const styles = {
    wrapper: {
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
      maxWidth: '400px',
      height: '200px',
      margin: '0 auto',
      backgroundColor: '#f5f5f5',
      borderRadius: '15px',
      gap: '2px',
      overflow: 'hidden',
    },
    colorBar: {
      display: 'flex',
      flexDirection: 'column' as const,
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.3s ease',
      position: 'relative' as const,
    },
    details: {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      gap: '8px',
      height: '100%',
      justifyContent: 'center',
      transition: 'opacity 0.3s ease',
    },
    text: {
      fontSize: '12px',
      fontWeight: '500',
      writingMode: 'vertical-rl' as const,
      transform: 'rotate(180deg)',
      whiteSpace: 'nowrap',
    },
  };

  return (
    <div style={styles.wrapper}>
      {colors.map(({ key, value }, index) => {
        const percentage = (value / total) * 100;
        const percentageStr = percentage.toFixed(1);
        const textColor = isColorDark(key) ? '#fff' : '#333';
        const isSmall = percentage < 8; // threshold for "small" columns

        return (
          <div
            key={index}
            style={{
              ...styles.colorBar,
              backgroundColor: key,
              width: `${percentage}%`,
              minWidth: isSmall ? '4px' : 'auto',
            }}
            onMouseOver={e => {
              e.currentTarget.style.width = `${Math.max(percentage, 15)}%`;
              const details = e.currentTarget.querySelector(
                '.details',
              ) as HTMLElement;
              if (details) details.style.opacity = '1';
            }}
            onMouseOut={e => {
              e.currentTarget.style.width = `${percentage}%`;
              const details = e.currentTarget.querySelector(
                '.details',
              ) as HTMLElement;
              if (details && isSmall) details.style.opacity = '0';
            }}
          >
            <div
              className='details'
              style={{
                ...styles.details,
                color: textColor,
                opacity: isSmall ? 0 : 1,
              }}
            >
              <span style={styles.text}>{key}</span>
              <span style={styles.text}>{percentageStr}%</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ColorWheelChart = <K extends React.Key, V extends any>({
  data,
  parser = (v: V) => v as number,
}: ColorPaletteProps<K, V>) => {
  if (!Array.isArray(data)) {
    return (
      <div>Selected property is not an array or contains no valid colors</div>
    );
  }

  return <ColorPalette data={data} parser={parser} />;
};

export default ColorWheelChart;
