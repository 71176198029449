import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { ICreativeData } from '../../models/creativeData';

interface CalculatedFieldsContextType {
  data: ICreativeData[];
  setData: React.Dispatch<React.SetStateAction<ICreativeData[]>>;
  loading: boolean;
  error: string | null;
  refreshData: () => Promise<void>;
}

const CalculatedFieldsContext = createContext<
  CalculatedFieldsContextType | undefined
>(undefined);
const samplesSize = 5;
export const CalculatedFieldsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const [data, setData] = useState<ICreativeData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchLatestData = useCallback(async () => {
    setLoading(true);
    setError(null);

    api.videos
      .all({ analyzed: true, pageSize: samplesSize })
      .then(res => res.docs.map(v => v.creativeData!))
      .then(setData);
  }, [api]);

  useEffect(() => {
    fetchLatestData();
  }, [fetchLatestData]);

  const refreshData = () => fetchLatestData();

  return (
    <CalculatedFieldsContext.Provider
      value={{ data, setData, loading, error, refreshData }}
    >
      {children}
    </CalculatedFieldsContext.Provider>
  );
};

export const useCalculatedFields = () => {
  const context = useContext(CalculatedFieldsContext);
  if (context === undefined) {
    throw new Error(
      'useCalculatedFields must be used within a CalculatedFieldsProvider',
    );
  }
  return context;
};
