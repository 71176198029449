import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthenticatedUser } from '../../contexts/auth';
import { useDebounce } from '../../hooks/useDebounce';
import {
  AnalyzedVideo,
  IInflightVideo,
  Platform,
  Source,
  sources,
} from '../../models';
import { Pagination } from '../../models/pagination';

const defaultPageSize = 12;
const LibraryContext = createContext<LibraryContextType | null>(null);

export const LibraryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayMode, setDisplayMode] = useState<DisplayMode>('grid');
  const [filters, setFilters] = useState<Filters>({});
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>({
    platforms: [],
    brands: [],
    countries: [],
    campaigns: [],
  });
  const [results, setResults] = useState<AnalyzedVideo<IInflightVideo>[]>([]);
  const [pagination, _setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: defaultPageSize,
    totalDocs: 0,
    totalPages: 100000,
  });
  const [isLoading, setIsLoading] = useState(true);
  const setPagination: React.Dispatch<SetStateAction<Pagination>> = (
    value: SetStateAction<Pagination>,
  ) => {
    _setPagination(prevState => {
      const v = typeof value === 'function' ? value(prevState) : value;
      if (deepEqual(prevState, v)) {
        //cancel the update
        return prevState;
      } else {
        return v;
      }
    });
  };

  const setFilter = useCallback(
    <K extends keyof Filters>(key: K) =>
      (value: Filters[K]) => {
        setFilters(f => ({ ...f, [key]: value }));
        setSearchParams(prev => {
          const newParams = new URLSearchParams(prev);
          if (value === undefined || value === null || value === '')
            newParams.delete(key);
          else newParams.set(key, value.toString());
          return newParams;
        });
      },
    [setSearchParams],
  );

  // We are using a ref to avoid multiple re-render of search when the pagination is updated
  const paginationRef = useRef(pagination);
  useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);

  const search = useCallback(() => {
    const { currentPage, pageSize } = paginationRef.current;
    setIsLoading(true);
    return api.videos
      .all<
        AnalyzedVideo<IInflightVideo>
      >({ ...filters, analyzed: true, pageSize, page: currentPage - 1 })
      .then(({ docs, currentPage, ...rest }) => {
        setResults(docs);
        setPagination({ currentPage: currentPage + 1, ...rest });
        setIsLoading(false);
      });
  }, [api, filters]);

  const setPage = useCallback(
    (page: number) => {
      setPagination(p => ({ ...p, currentPage: page }));
      setSearchParams(prev => {
        const newParams = new URLSearchParams(prev);
        newParams.set('page', page.toString());
        return newParams;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (
      pagination.currentPage > pagination.totalPages &&
      pagination.totalPages > 0
    )
      setPage(pagination.totalPages);
  }, [pagination, setPage]);

  const debouncedSearch = useDebounce(search, 50);

  useEffect(() => {
    debouncedSearch();
  }, [debouncedSearch]);

  const { platforms, brands, countries, campaigns } = availableFilters;
  const setAvailable =
    <K extends keyof AvailableFilters>(key: K) =>
    (value: AvailableFilters[K]) =>
      setAvailableFilters(af => ({ ...af, [key]: value }));

  const { platform, brand, country, campaign } = filters;
  useEffect(() => {
    api.videos.platforms({ analyzed: true }).then(setAvailable('platforms'));
  }, [api]);
  useEffect(() => {
    api.videos
      .brands({ platform, analyzed: true })
      .then(setAvailable('brands'));
  }, [api, platform]);
  useEffect(() => {
    api.videos
      .countries({ brand, platform, analyzed: true })
      .then(setAvailable('countries'));
  }, [api, brand, platform]);
  useEffect(() => {
    api.videos
      .campaigns({ brand, platform, country, analyzed: true })
      .then(setAvailable('campaigns'));
  }, [api, brand, platform, country]);

  useEffect(() => {
    if (platform && !platforms.includes(platform))
      setFilter('platform')(undefined);
  }, [platforms, platform, setFilter]);
  useEffect(() => {
    if (brand && !brands.includes(brand)) setFilter('brand')(undefined);
  }, [brands, brand, setFilter]);
  useEffect(() => {
    if (country && !countries.includes(country))
      setFilter('country')(undefined);
  }, [countries, country, setFilter]);
  useEffect(() => {
    if (campaign && !campaigns.includes(campaign))
      setFilter('campaign')(undefined);
  }, [campaigns, campaign, setFilter]);

  const getInitialFiltersFromURL = useCallback((): Filters => {
    const initialFilters: Filters = {};
    const platform = searchParams.get('platform');
    const brand = searchParams.get('brand');
    const country = searchParams.get('country');
    const campaign = searchParams.get('campaign');
    const source = searchParams.get('source') as Source;
    const query = searchParams.get('query');

    if (platform && availableFilters.platforms.includes(platform as Platform))
      initialFilters.platform = platform as Platform;
    if (brand && availableFilters.brands.includes(brand))
      initialFilters.brand = brand;
    if (country && availableFilters.countries.includes(country))
      initialFilters.country = country;
    if (campaign && availableFilters.campaigns.includes(campaign))
      initialFilters.campaign = campaign;
    if (source && sources.includes(source)) initialFilters.source = source;
    if (query) initialFilters.query = query;

    return initialFilters;
  }, [searchParams, availableFilters]);

  useEffect(() => {
    const initialFilters = getInitialFiltersFromURL();
    setFilters(initialFilters);
    const p = searchParams.get('page');
    if (p) setPage(parseInt(p));
  }, [getInitialFiltersFromURL, setPage, searchParams]);

  const value = {
    displayMode,
    setDisplayMode,
    filters,
    setFilter,
    availableFilters,
    results,
    setPage,
    pagination,
    isLoading,
  };
  return (
    <LibraryContext.Provider value={value}>{children}</LibraryContext.Provider>
  );
};

type DisplayMode = 'grid' | 'list';
interface Filters {
  platform?: Platform;
  brand?: string;
  country?: string;
  campaign?: string;
  source?: Source;
  query?: string;
}
interface AvailableFilters {
  platforms: Platform[];
  brands: string[];
  countries: string[];
  campaigns: string[];
}

interface LibraryContextType {
  displayMode: DisplayMode;
  setDisplayMode: Dispatch<React.SetStateAction<DisplayMode>>;
  filters: Filters;
  availableFilters: AvailableFilters;
  setFilter: <K extends keyof Filters>(key: K) => (value: Filters[K]) => void;
  results: AnalyzedVideo<IInflightVideo>[];
  setPage: (page: number) => void;
  pagination: Pagination;
  isLoading: boolean;
}

export default function useLibrary() {
  const context = useContext(LibraryContext);
  if (!context)
    throw new Error('useLibrary must be used within a LibraryProvider');
  return context;
}

var deepEqual = function (x: any, y: any) {
  if (x === y) {
    return true;
  } else if (
    typeof x == 'object' &&
    x != null &&
    typeof y == 'object' &&
    y != null
  ) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};
