import React from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import useGuidelines from '../../contexts/guidelines';
import { IBrandGuideline, IPlatformGuideline } from '../../models/guidelines';

type GuidelineType = IBrandGuideline | IPlatformGuideline;

const GuidelinesLayout: React.FC<{
  title: string;
  guidelines: GuidelineType[];
}> = ({ title, guidelines }) => (
  <Stack spacing={3}>
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Typography variant='h4' fontWeight='bold'>
        {title}
      </Typography>
      <Button
        component={NavLink}
        to='new'
        variant='contained'
        color='primary'
        sx={{ borderRadius: 2 }}
      >
        New Guideline
      </Button>
    </Box>
    <Grid container spacing={3}>
      {guidelines.map(g => (
        <Grid item key={g._id} xs={12} sm={6} md={4} lg={3}>
          <GuidelineCard guideline={g} />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export const BrandGuidelines: React.FC = () => {
  const { brandGuidelines } = useGuidelines();
  return (
    <GuidelinesLayout title='Brand Guidelines' guidelines={brandGuidelines} />
  );
};

export const PlatformGuidelines: React.FC = () => {
  const { platformGuidelines } = useGuidelines();
  return (
    <GuidelinesLayout
      title='Platform Guidelines'
      guidelines={platformGuidelines}
    />
  );
};

const GuidelineCard: React.FC<{
  guideline: GuidelineType;
}> = ({ guideline }) => {
  const title = 'brand' in guideline ? guideline.brand : guideline.platform;
  const categoryCount = guideline.categories?.length || 0;
  const ruleCount =
    guideline.categories?.reduce(
      (acc, cat) => acc + (cat.rules?.length || 0),
      0,
    ) || 0;

  return (
    <Card
      sx={{
        height: '100%',
        boxShadow: '1px 7px 40px 0px #0000001a',
        borderRadius: 4,
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '1px 7px 40px 0px #ab812d24',
          backgroundColor: '#f6eabb',
        },
      }}
    >
      <CardActionArea
        component={NavLink}
        to={guideline._id}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <CardContent sx={{ width: '80%' }}>
          <Typography variant='h6' fontWeight='bold' gutterBottom>
            {title}
          </Typography>
          <Box display='flex' justifyContent='space-between' mt={2}>
            <Typography variant='body2' color='text.secondary'>
              {categoryCount} {categoryCount === 1 ? 'Category' : 'Categories'}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {ruleCount} {ruleCount === 1 ? 'Rule' : 'Rules'}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
