import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { PerfKey } from '../../../models';
import IInsight from '../../../models/insights';
import BookmarkButton from './BookmarkButton';
import InsightIcon from './InsightIcon';

interface InsightDetailProps {
  insight: IInsight;
}
const InsightDetail: React.FC<InsightDetailProps> = ({ insight }) => {
  return (
    <Stack
      direction='row'
      position='relative'
      justifyContent='space-between'
      sx={{
        background:
          'linear-gradient(180deg, #EDEFFD 0%, #F6F5EE 47%, #F7F5ED 100%)',
        borderRadius: '15px',
        p: '25px',
      }}
      spacing={2}
      height='100%'
      boxSizing='border-box'
    >
      <Box position='absolute' right={0} top={0}>
        <BookmarkButton insight={insight} />
      </Box>
      <Stack justifyContent='space-between'>
        <Box display='flex' gap={2} alignItems='center'>
          <InsightIcon isPositive={insight.isPositive} />
          <div>
            <Typography variant='body1' fontSize={18} gap={2} paragraph>
              {insight.content}
            </Typography>
            <Typography fontSize={12} color='text.secondary'>
              {insight.filters.start &&
                `Start: ${formatDate(insight.filters.start)}`}
            </Typography>
            <Typography fontSize={12} color='text.secondary'>
              {insight.filters.end && `End: ${formatDate(insight.filters.end)}`}
            </Typography>
            <Typography fontSize={12} color='text.secondary'>
              {`${insight.numberOfVideos} videos analyzed`}
            </Typography>
          </div>
        </Box>

        <Box>
          <Typography fontSize={10} gutterBottom color='text.secondary'>
            Calculated for
          </Typography>
          <Box display='flex' justifyContent='left' gap={1}>
            <Chip label={insight.filters.country || 'All Countries'} />
            <Chip label={insight.filters.platform || 'All Platforms'} />
            <Chip label={insight.filters.brand || 'All Brands'} />
          </Box>
        </Box>
      </Stack>
      <Stack>
        <Box>
          <Typography variant='subtitle1' gutterBottom>
            {insight.factor.category} &gt; {insight.factor.title}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {insight.factor.description}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant='subtitle1' gutterBottom>
            {insight.metric}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {metricsDescription[insight.metric]}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

const metricsDescription: Record<PerfKey, string> = {
  WatchTime:
    'The total amount of time users spend watching a video. It measures engagement and content quality.',
  RetentionRate:
    'The percentage of viewers who continue watching a video over time. It indicates how well the content maintains audience interest.',
  ConversionRate:
    'The percentage of users who take a desired action (e.g., make a purchase, sign up) after viewing the content.',
  views:
    'This is the number of times users actively engage with the content by watching or interacting with it. Criterias might vary depending on the platform.',
  impressions:
    "This is the number of times the content is displayed on a screen, regardless of whether it's interacted with, or even watched.",
  CTR: 'Click-Through Rate is the percentage of people who click on a specific link or call to action out of the total number who view a page, email, or advertisement.',
};

// Helper function to format date
function formatDate(date?: Date | string): string {
  if (typeof date === 'string') date = new Date(date);
  return date
    ? date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
    : 'N/A';
}

export default InsightDetail;
