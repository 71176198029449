import {
  CheckBox,
  ChevronRight,
  DateRange,
  ExpandMore,
  Folder,
  Help,
  List,
  MoreVert,
  Numbers,
  TextFields,
} from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

interface TreeViewProps {
  data: Record<string, any>;
  onSelect: (path: string, value: any) => void;
}

const TreeView: React.FC<TreeViewProps> = ({ data, onSelect }) => {
  const [expanded, setExpanded] = useState<Set<string>>(new Set());
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedPath, setSelectedPath] = useState<string | null>(null);

  const toggleExpand = useCallback((path: string) => {
    setExpanded(prev => {
      const next = new Set(prev);
      if (next.has(path)) {
        next.delete(path);
      } else {
        next.add(path);
      }
      return next;
    });
  }, []);

  const filterTree = useCallback(
    (obj: Record<string, any>, path: string = ''): Record<string, any> => {
      const result: Record<string, any> = {};
      if (!obj) return {};
      Object.entries(obj).forEach(([key, value]) => {
        const currentPath = path ? `${path}.${key}` : key;
        if (currentPath.toLowerCase().includes(filter.toLowerCase())) {
          result[key] = value;
        } else if (typeof value === 'object' && value !== null) {
          const filteredChild = filterTree(value, currentPath);
          if (Object.keys(filteredChild).length > 0) {
            result[key] = filteredChild;
          }
        }
      });
      return result;
    },
    [filter],
  );

  const filteredData = useMemo(() => filterTree(data), [data, filterTree]);

  const getNodeIcon = (value: any): JSX.Element => {
    if (Array.isArray(value)) return <List className='tree-view-icon' />;
    if (value instanceof Date) return <DateRange className='tree-view-icon' />;
    if (typeof value === 'object' && value !== null)
      return <Folder className='tree-view-icon' />;
    if (typeof value === 'number')
      return <Numbers className='tree-view-icon' />;
    if (typeof value === 'string')
      return <TextFields className='tree-view-icon' />;
    if (typeof value === 'boolean')
      return <CheckBox className='tree-view-icon' />;
    return <Help className='tree-view-icon' />;
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    path: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedPath(path);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPath(null);
  };

  const toPhrase = (text: string) => {
    // Split the camelCase text into words
    const words = text.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join with spaces
    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleArrayOperation = (operation: string) => {
    if (selectedPath) {
      let formula = '';
      switch (operation) {
        case 'MAP':
          formula = `MAP(${selectedPath}, "item")`;
          break;
        case 'FILTER':
          formula = `FILTER(${selectedPath}, "item == 'filter_value'")`;
          break;
        case 'REDUCE':
          formula = `REDUCE(${selectedPath}, "(accumulator, item) => accumulator + item", 0)`;
          break;
        default:
          formula = `${operation}(${selectedPath})`;
      }
      onSelect(formula, []);
      handleMenuClose();
    }
  };

  const isExpandable = (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length > 0;
    }
    return false;
  };

  // In the renderTree function, replace the existing isExpandable check with:

  const renderTree = (
    obj: Record<string, any>,
    path: string = '',
  ): JSX.Element => {
    return (
      <ul className='tree-view-list'>
        {Object.entries(obj).map(([key, value]) => {
          let currentPath = path ? `${path}.${key}` : key;
          const canExpand = isExpandable(value);
          const isExpanded = expanded.has(currentPath);
          const isArray = Array.isArray(value);

          if (Number.isInteger(Number(key)))
            currentPath = path ? `${path}[${key}]` : key;

          return (
            <li key={currentPath} className='tree-view-item'>
              <div className='tree-view-item-content'>
                {canExpand && (
                  <button
                    onClick={() => toggleExpand(currentPath)}
                    className='tree-view-toggle'
                  >
                    {isExpanded ? <ExpandMore /> : <ChevronRight />}
                  </button>
                )}
                {getNodeIcon(value)}
                <span
                  onClick={() => onSelect(currentPath, value)}
                  className='tree-view-label'
                >
                  <span className='tree-view-key'>{toPhrase(key)}:</span>
                  {canExpand ? (
                    <span className='tree-view-expandable'>
                      {isArray ? `[${value.length}]` : ''}
                    </span>
                  ) : (
                    <span className='tree-view-value'>{String(value)}</span>
                  )}
                </span>
                {isArray && (
                  <IconButton
                    size='small'
                    onClick={e => handleMenuOpen(e, currentPath)}
                    className='tree-view-array-menu-button'
                  >
                    <MoreVert fontSize='small' />
                  </IconButton>
                )}
              </div>
              {canExpand && isExpanded && renderTree(value, currentPath)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className='tree-view-container'>
      <div className='tree-view-search'>
        <input
          type='text'
          placeholder='Filter nodes...'
          value={filter}
          onChange={e => setFilter(e.target.value)}
          className='tree-view-search-input'
        />
      </div>
      {Object.keys(filteredData).length === 0 ? (
        <div className='tree-view-alert' role='alert'>
          <span>No matching nodes found.</span>
        </div>
      ) : (
        renderTree(filteredData)
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleArrayOperation('MAP')}>MAP</MenuItem>
        <MenuItem onClick={() => handleArrayOperation('FILTER')}>
          FILTER
        </MenuItem>
        <MenuItem onClick={() => handleArrayOperation('REDUCE')}>
          REDUCE
        </MenuItem>
        <MenuItem onClick={() => handleArrayOperation('LENGTH')}>
          LENGTH
        </MenuItem>
        <MenuItem onClick={() => handleArrayOperation('DISTINCT')}>
          DISTINCT
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TreeView;
