import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import InsightCards from '../Cards';
import { InsightProvider, useInsight } from './context';
import InsightSpotterDisclaimer from './Disclaimer';
import InsightSpotterFilters from './Filters';

const InsightSpotter: React.FC = () => {
  return (
    <InsightProvider>
      <Stack height='100%'>
        <InsightSpotterDisclaimer />
        <InsightSpotterFilters />
        <InsightsSpotterResults />
      </Stack>
    </InsightProvider>
  );
};
export default InsightSpotter;

const InsightsSpotterResults: React.FC = () => {
  const { insights, setInsights } = useInsight();
  return (
    <Box flex={1} overflow='auto' p={2}>
      {insights.length === 0 ? (
        <Box textAlign='center'>
          <Typography variant='body1' color={'text.secondary'} gutterBottom>
            Use the filter and click on "Analyze" to calculate the insights
          </Typography>
        </Box>
      ) : (
        <InsightCards insights={insights} setInsights={setInsights} />
      )}
    </Box>
  );
};
