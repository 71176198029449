import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import InsightChart from './Chart';
import InsightDetail from './Detail';
import useInsightCards from './context';

const InsightFocus: React.FC = () => {
  const { selectedInsight, setSelectedInsight } = useInsightCards();
  if (!selectedInsight) return null;

  return (
    <Grid2 container spacing={2} position='relative' mb={1}>
      <Box position='absolute' right={0} m={1}>
        <IconButton onClick={() => setSelectedInsight(null)}>
          <Close />
        </IconButton>
      </Box>

      <Grid2 xs={12} md={6}>
        <InsightDetail insight={selectedInsight} />
      </Grid2>
      <Grid2 xs={12} md={6} minHeight='300px'>
        <InsightChart insight={selectedInsight} />
      </Grid2>
    </Grid2>
  );
};
export default InsightFocus;
