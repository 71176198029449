import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import useCdStructures from '../../contexts/cdStructures';
import { useDebounce } from '../../hooks/useDebounce';
import { IVideoSummary, Platform, Source } from '../../models';
import { ICreativeDataStructure } from '../../models/creativeDataStructure';

// year to date
const defaultStartDate = new Date(new Date().getFullYear(), 0, 1);
// today
const defaultEndDate = new Date();

interface Filters {
  platform?: Platform;
  brand?: string;
  country?: string;
  source?: Source;
  start?: Date;
  end?: Date;
}

interface AvailableFilters {
  platforms: Platform[];
  brands: string[];
  countries: string[];
}

interface DashboardContextType {
  filters: Filters;
  selectedCd: ICreativeDataStructure[];
  availableFilters: AvailableFilters;
  setFilter: <K extends keyof Filters>(key: K) => (value: Filters[K]) => void;
  results?: IVideoSummary;
  isLoading: boolean;
}

const DashboardContext = createContext<DashboardContextType | null>(null);

export const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const { dashboardCdStructures } = useCdStructures();
  const [filters, setFilters] = useState<Filters>({
    start: defaultStartDate,
    end: defaultEndDate,
  });
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>({
    platforms: [],
    brands: [],
    countries: [],
  });
  const [results, setResults] = useState<IVideoSummary>();
  const [isLoading, setIsLoading] = useState(true);

  const setFilter = useCallback(
    <K extends keyof Filters>(key: K) =>
      (value: Filters[K]) =>
        setFilters(f => ({ ...f, [key]: value })),
    [],
  );

  const search = useCallback(() => {
    setIsLoading(true);
    return api.videos
      .summary({ analyzed: true, ...filters })
      .then(setResults)
      .catch(error => {
        console.error('Error fetching video summary:', error);
        setResults(undefined); // Reset on error
      })
      .finally(() => setIsLoading(false));
  }, [api, filters]);

  const debouncedSearch = useDebounce(search, 50);

  useEffect(() => {
    debouncedSearch();
  }, [debouncedSearch]);

  const setAvailable = useCallback(
    <K extends keyof AvailableFilters>(key: K) =>
      (value: AvailableFilters[K]) =>
        setAvailableFilters(af => ({ ...af, [key]: value })),
    [],
  );

  useEffect(() => {
    api.videos.platforms({ analyzed: true }).then(setAvailable('platforms'));
  }, [api, setAvailable]);

  useEffect(() => {
    api.videos
      .brands({ platform: filters.platform, analyzed: true })
      .then(setAvailable('brands'));
  }, [api, filters.platform, setAvailable]);

  useEffect(() => {
    api.videos
      .countries({
        brand: filters.brand,
        platform: filters.platform,
        analyzed: true,
      })
      .then(setAvailable('countries'));
  }, [api, filters.brand, filters.platform, setAvailable]);

  useEffect(() => {
    if (
      filters.platform &&
      !availableFilters.platforms.includes(filters.platform)
    )
      setFilter('platform')(undefined);
    if (filters.brand && !availableFilters.brands.includes(filters.brand))
      setFilter('brand')(undefined);
    if (
      filters.country &&
      !availableFilters.countries.includes(filters.country)
    )
      setFilter('country')(undefined);
  }, [availableFilters, filters, setFilter]);

  const value: DashboardContextType = {
    filters,
    selectedCd: dashboardCdStructures,
    setFilter,
    availableFilters,
    results,
    isLoading,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export default function useDashboard() {
  const context = useContext(DashboardContext);
  if (!context)
    throw new Error('useDashboard must be used within a DashboardProvider');
  return context;
}
