import { IStructure, UiType } from '../models/creativeDataStructure';

export function buildNestedSetState<T, K extends keyof T>(
  setObj: React.Dispatch<React.SetStateAction<T>>,
  key: K,
): React.Dispatch<React.SetStateAction<T[K]>> {
  return (value: React.SetStateAction<T[K]>) => {
    setObj(prevState => {
      const newState = (
        Array.isArray(prevState) ? [...prevState] : { ...prevState }
      ) as T;
      if (typeof value === 'function')
        newState[key] = (value as (prev: T[K]) => T[K])(prevState[key]);
      else newState[key] = value;
      return newState;
    });
  };
}

export const getGridSize = (structure: IStructure<UiType>): number => {
  switch (structure.uiType) {
    case 'presences':
      return 8;
    case 'worldMapChart':
    case 'stackedChart':
    case 'barChart':
    case 'colorPalette':
    case 'tagCloud':
    case 'spiderChart':
    case 'positionMatrix':
    case 'presenceSummary':
      return 4;
    case 'simpleList':
      return 2;
    default:
      return 2;
  }
};

export const getNestedValue = (obj: any, path: string): any => {
  const keys = path.split('.');

  let current = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (!current) return;

    // If last element is an array, return a joined string with all values
    if (Array.isArray(current) && i === keys.length - 1) {
      return current.map(item => item[key]).join(', ');
    }

    current = current[key];
  }

  return current;
};

export const cdIsEmptyOrNull = (creativeData: any): boolean => {
  // Handle specific cases of values
  if (creativeData === null || creativeData === undefined) return true;
  if (typeof creativeData === 'string' && creativeData.trim() === '')
    return true;
  if (typeof creativeData === 'string' && creativeData === 'unknown')
    return true;

  // Handle arrays by checking each element recursively
  if (Array.isArray(creativeData)) {
    return creativeData.every(item => cdIsEmptyOrNull(item));
  }

  // Handle objects by checking each property recursively
  if (typeof creativeData === 'object' && creativeData !== null) {
    if (creativeData.hasOwnProperty('value') && creativeData.value === 0) {
      return true;
    }
    if (creativeData.hasOwnProperty('key') && creativeData.key === 'unknown') {
      return true;
    }
    return Object.values(creativeData).every(value => cdIsEmptyOrNull(value));
  }

  // Handle booleans: `false` should not be considered empty
  if (typeof creativeData === 'boolean') return false;

  // If it's any other type and didn't meet the conditions above, it's not empty
  return false;
};

export function longestCommonSubstring(s1: string, s2: string): string {
  const len1 = s1.length;
  const len2 = s2.length;
  const table: number[][] = Array.from({ length: len1 + 1 }, () =>
    Array(len2 + 1).fill(0),
  );
  let maxLength = 0;
  let endIndex = 0;

  for (let i = 1; i <= len1; i++)
    for (let j = 1; j <= len2; j++)
      if (s1[i - 1] === s2[j - 1]) {
        table[i][j] = table[i - 1][j - 1] + 1;
        if (table[i][j] > maxLength) {
          maxLength = table[i][j];
          endIndex = i;
        }
      }

  return s1.slice(endIndex - maxLength, endIndex);
}

export function stringsSimilarity(s1: string, s2: string): number {
  return longestCommonSubstring(s1, s2).length / Math.max(s1.length, s2.length);
}
