import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { KeyValueItem } from '../../models/creativeData';
import { IStructure } from '../../models/creativeDataStructure';
import { getColor } from '../../theme/colors';
export interface BarChartItem {
  name: string;
  value: number;
}

type SimpleBarChartProps<K extends React.Key, V extends any> = {
  data: KeyValueItem<K, V>[];
  structure: IStructure;
} & (V extends number ? {} : { parser: (value: V) => number });

const CustomLegend: React.FC<{ payload: any[] }> = ({ payload }) => (
  <div
    style={{
      padding: 0,
      width: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    }}
  >
    {payload.map((entry, index) => (
      <div key={`item-${index}`} style={{ marginBottom: 5 }}>
        <span
          style={{
            display: 'inline-block',
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: entry.name.startsWith('#')
              ? entry.name
              : getColor(index),
            marginRight: 5,
          }}
        ></span>
        <Typography fontSize={13} display={'inline-block'}>
          {entry.name}
        </Typography>
      </div>
    ))}
  </div>
);

const SimpleBarChart = <K extends React.Key, V extends any>(
  props: SimpleBarChartProps<K, V>,
) => {
  const { data, structure } = props;
  const parser = 'parser' in props ? props.parser : (v: V) => v as number;
  const [activeIndex, setActiveIndex] = useState(-1);

  const processedData = data.filter(
    item => item.key !== 'null' && item.key !== null,
  );
  const totalValues = processedData.reduce(
    (acc, item) => acc + parser(item.value),
    0,
  );

  if (totalValues === 0) {
    return (
      <Box
        style={{ width: '100%', height: 200 }}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography>Creative Data not available for this media</Typography>
      </Box>
    );
  }

  const chartData = processedData
    .sort((a, b) => {
      const aKey = String(a.key);
      const bKey = String(b.key);
      if (aKey.startsWith('#') && bKey.startsWith('#')) {
        // Convert hex to RGB and calculate brightness
        const getBrightness = (hex: string) => {
          const r = parseInt(hex.slice(1, 3), 16);
          const g = parseInt(hex.slice(3, 5), 16);
          const b = parseInt(hex.slice(5, 7), 16);
          return (r * 299 + g * 587 + b * 114) / 1000;
        };
        return getBrightness(bKey) - getBrightness(aKey); // Sort darker to lighter
      }
      return 0;
    })
    .map(item => ({
      name: String(item.key),
      value: parser(item.value),
      displayValue: Math.max(parser(item.value), 0.1), // Ensure minimum visible value
      percentage:
        totalValues === 0 ? 0 : (parser(item.value) / totalValues) * 100,
    }));

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(-1);
  };
  let nonZeroData = chartData;
  return (
    <Box style={{ width: '100%', height: 200 }} display={'flex'}>
      {structure.showLabels && <CustomLegend payload={nonZeroData} />}
      <ResponsiveContainer>
        <BarChart
          data={nonZeroData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey='name' hide />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={value => (Number.isInteger(value) ? value : '')}
          />
          <Tooltip
            labelStyle={{ display: 'none' }}
            formatter={(value: number, name: string, props: any) => [
              `${props.payload.percentage.toFixed(2)}%`,
              structure.showLabels ? props.payload.name : 'Percentage',
            ]}
          />
          <Bar
            dataKey='displayValue'
            fill='#8884d8'
            radius={10}
            minPointSize={10}
            onMouseEnter={(_d, i) => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.name.startsWith('#') ? entry.name : getColor(index)}
                opacity={index === activeIndex ? 0.8 : 1}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SimpleBarChart;
