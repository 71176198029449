import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useAuthenticatedUser } from '../../../contexts/auth';
import IInsight from '../../../models/insights';
import useInsightCards from './context';

interface BookmarkButtonProps {
  insight: IInsight;
}
const BookmarkButton: React.FC<BookmarkButtonProps> = props => {
  const { insight } = props;
  const { updateInsight } = useInsightCards();
  const { api } = useAuthenticatedUser();
  const toggleBookmark = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      api.insights.toggleBookmark(insight._id, insight).then(updateInsight);
    },
    [api, insight, updateInsight],
  );
  return (
    <IconButton color='primary' onClick={toggleBookmark}>
      {insight.isBookmarked ? <Favorite /> : <FavoriteBorder />}
    </IconButton>
  );
};
export default BookmarkButton;
