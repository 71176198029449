import SocialIcon from '@mui/icons-material/InterestsOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import ConversionIcon from '@mui/icons-material/Refresh';
import BrandIcon from '@mui/icons-material/Storefront';
import WatchTimeIcon from '@mui/icons-material/Timer10Outlined';
import GlobalGuidelinesScore from './GuidelinesScore';

import { Stack, SvgIconProps, Typography } from '@mui/material';
import React from 'react';
import useVideoDashboard from './context';
const styles = {
  card: {
    width: '100%',
    backgroundColor: '#f3f4f6',
    borderRadius: '8px',
    padding: '16px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#4b5563',
    fontWeight: 500,
    fontSize: '16px',
    margin: 0,
  },
  icon: {
    color: '#9ca3af',
  },
};
const formatValue = (value: number): string => {
  const roundedValue = Math.round(value * 10) / 10; // Round to 1 decimal place

  if (roundedValue >= 1000000000) {
    return (roundedValue / 1000000000).toFixed(1) + 'B';
  } else if (roundedValue >= 1000000) {
    return (roundedValue / 1000000).toFixed(1) + 'M';
  } else if (roundedValue >= 1000) {
    return (roundedValue / 1000).toFixed(1) + 'K';
  } else {
    return roundedValue.toFixed(1);
  }
};

const Metadata: React.FC = () => {
  const { video } = useVideoDashboard();
  const metadata = video.metadata;
  const { country, platform, brand } = metadata;
  const lastMeasure =
    'measures' in video &&
    video.measures.sort((a, b) => a.date.valueOf() - b.date.valueOf())[0];
  const { views, impressions } = lastMeasure || {
    views: undefined,
    impressions: undefined,
  };
  return (
    <Stack direction='row' spacing={2} p={2}>
      <MetaCard title='Platform' value={platform} />
      <MetaCard title='Brand' value={brand} />
      <MetaCard title='Location' value={country} />
      {impressions && (
        <MetaCard title='Impressions' value={formatValue(impressions)} />
      )}
      {views && <MetaCard title='Views' value={formatValue(views)} />}
      <MetaCard title='Scores' value={''} />
    </Stack>
  );
};
export default Metadata;

type MetaCardProps = {
  title: string;
  value: string | number;
  icon?: React.ReactElement;
  color?: string;
};
const MetaCard: React.FC<MetaCardProps> = props => {
  const { title, value, icon, color } = props;

  return (
    <div style={styles.card}>
      {title !== 'Scores' && (
        <div style={styles.header}>
          <Typography sx={styles.title}>{title}</Typography>
          {/* icon and value */}
          <span style={styles.icon}>
            {title === 'Platform' && <SocialIcon />}
            {title === 'Location' && <LocationIcon />}
            {title === 'Impressions' && <ConversionIcon />}
            {title === 'Views' && <WatchTimeIcon />}
            {title === 'Brand' && <BrandIcon />}
          </span>
        </div>
      )}
      <Stack direction='row' spacing={1} alignItems={'center'} height={'100%'}>
        {title !== 'Scores' && (
          <>
            {icon && <IconWrapper icon={icon} size={20} />}
            <Typography
              color={color}
              sx={{
                fontWeight: 600,
                fontSize: '1.4rem',
                letterSpacing: '0.1px',
                textTransform: 'capitalize',
              }}
            >
              {value}
            </Typography>
          </>
        )}
        {title === 'Scores' && <GlobalGuidelinesScore variant={'small'} />}
      </Stack>
    </div>
  );
};

interface IconWrapperProps extends SvgIconProps {
  icon: React.ReactElement;
  size?: number | string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  icon,
  size = 20,
  ...props
}) => {
  return React.cloneElement(icon, {
    ...props,
    style: { fontSize: size, ...props.style },
  });
};
