// dollar sign
import SpendIcon from '@mui/icons-material/AttachMoney';

import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import MinimalistChart from './Charts/MinimalistChart';
//reload icon

interface PerformanceCardProps {
  title: string;
  iconName: string;
  value: number;
  unit: string;
  data: number[];
}

const formatValue = (value: number): string => {
  const roundedValue = Math.round(value * 10) / 10; // Round to 1 decimal place

  if (roundedValue >= 1000000000) {
    return (roundedValue / 1000000000).toFixed(1) + 'B';
  } else if (roundedValue >= 1000000) {
    return (roundedValue / 1000000).toFixed(1) + 'M';
  } else if (roundedValue >= 1000) {
    return (roundedValue / 1000).toFixed(1) + 'K';
  } else {
    return roundedValue.toFixed(1);
  }
};

const PerformanceCard: React.FC<PerformanceCardProps> = ({
  title,
  iconName,
  value,
  unit,
  data,
}) => {
  const styles = {
    card: {
      width: '100%',
      backgroundColor: '#f3f4f6',
      borderRadius: '8px',
      padding: '16px',
      height: '190px',
      display: 'flex',
      flexDirection: 'column' as const,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },
    title: {
      color: '#4b5563',
      fontWeight: 500,
      fontSize: '16px',
      margin: 0,
    },
    icon: {
      color: '#9ca3af',
    },
    value: {
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: '8px',
    },
    unit: {
      fontSize: '16px',
      marginLeft: '2px',
    },
    chart: {
      height: '64px',
      flexGrow: 1,
    },
  };

  return (
    <div style={styles.card}>
      <div style={styles.header}>
        <h2 style={styles.title}>{title}</h2>
        <span style={styles.icon}>
          {iconName === 'Impressions' && <VisibilityIcon />}
          {iconName === 'Spend' && <SpendIcon />}
        </span>
      </div>
      <div style={styles.value}>
        {formatValue(value)}
        <span style={styles.unit}>{unit}</span>
      </div>
      <MinimalistChart data={data} />
    </div>
  );
};

export default PerformanceCard;
