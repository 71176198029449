import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Brand } from '../models';
import { useAuthenticatedUser } from './auth';

const GlobalContext = createContext<GlobalContextType | null>(null);

export const GlobalProvider: FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const [brands, setBrands] = useState<Brand[]>([]);
  useEffect(() => {
    api.brands.all().then(setBrands);
  }, [api]);

  const value = { brands };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

interface GlobalContextType {
  brands: Brand[];
}

function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === null) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }
  return context;
}

export function useBrands() {
  const { brands } = useGlobal();
  const getBrand = useCallback(
    (BrandID: number) => brands.find(brand => brand.BrandID === BrandID),
    [brands],
  );
  return { brands, getBrand };
}
