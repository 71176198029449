import FieldBuilder from './FieldBuilder';
import { CalculatedFieldsProvider } from './context';

const CalculatedFields: React.FC = () => {
  return (
    <CalculatedFieldsProvider>
      <FieldBuilder />
    </CalculatedFieldsProvider>
  );
};

export default CalculatedFields;
