import * as Icons from '@mui/icons-material';
import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useMemo, useState } from 'react';
import NormalizedSelect from '../../components/NormalizedSelect';
import { ArrayType, SimpleType } from '../../models/creativeDataStructure';
import {
  buildOperation,
  getAvailableOperations,
  ICategory,
  IGuideline,
  IOperation,
  IRule,
  OperationName,
  validateCategory,
  validateGuideline,
  validateRule,
} from '../../models/guidelines';
import { buildNestedSetState } from '../../utils/misc';
import { ManagedState, ManagedStateFC, Optional } from '../../utils/typing';
import IconPicker, { IconName } from '../General/IconPicker';
import useCategories, { CategoriesProvider } from './context';

interface GuidelineManagerProps<T extends Optional<IGuideline, '_id'>>
  extends ManagedState<'guideline', T> {
  save: (guideline: T) => Promise<T>;
}

export default function GuidelineManager<T extends Optional<IGuideline, '_id'>>(
  props: GuidelineManagerProps<T>,
) {
  const { guideline, setGuideline, save: saveFn } = props;
  const save = () => saveFn(guideline).then(setGuideline);
  const setCategories = buildNestedSetState(setGuideline, 'categories');
  const newCategory = () =>
    setCategories(prev => [
      ...prev,
      { name: 'New Category', rules: [], weight: 0 },
    ]);

  return (
    <CategoriesProvider
      categories={guideline.categories}
      setCategories={setCategories}
    >
      <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 3 }}>
        <Typography variant='h4' gutterBottom>
          L'Oréal Guidelines
        </Typography>
        <Typography variant='body1' paragraph>
          Use the form to manage your brand guidelines. Ensure the sum of the
          weights for each group equals 1. Please note that the guidelines will
          be recalculated after your changes, so updates may take some time to
          take effect.
        </Typography>
        <Button
          variant='outlined'
          startIcon={<Add />}
          onClick={newCategory}
          sx={{ marginBottom: 3 }}
        >
          New Category
        </Button>
        <Categories />
        <Button
          variant='contained'
          color='primary'
          onClick={save}
          disabled={!validateGuideline(guideline)}
          sx={{ marginTop: 3 }}
        >
          Save
        </Button>
      </Box>
    </CategoriesProvider>
  );
}

const Categories: React.FC = () => {
  const { categories, setCategories } = useCategories();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {categories.map((category, i) => (
        <Category
          key={i}
          category={category}
          setCategory={buildNestedSetState(setCategories, i)}
        />
      ))}
    </Box>
  );
};
const Category: ManagedStateFC<'category', ICategory> = props => {
  const { category, setCategory } = props;
  const { createRule, deleteCategory } = useCategories();
  const [isEditing, setIsEditing] = useState(false);
  const [showIconPicker, setShowIconPicker] = useState(false);

  const setCategoryTitle = buildNestedSetState(setCategory, 'name');
  const setCategoryWeight = buildNestedSetState(setCategory, 'weight');
  const setCategoryIcon = buildNestedSetState(setCategory, 'icon');
  const newRule = () => createRule(category.name);

  const handleIconSelect = (iconName: IconName) => {
    setCategoryIcon(iconName);
    setShowIconPicker(false);
  };

  const valid = useMemo(() => {
    return validateCategory(category);
  }, [category]);

  return (
    <Card
      sx={{
        borderRadius: '20px',
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: valid ? 'transparent' : 'red',
      }}
      variant='outlined'
    >
      <Box
        sx={{
          backgroundColor: '#4c4c7c',
          color: 'white',
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            onClick={() => setShowIconPicker(true)}
            sx={{ color: 'white' }}
          >
            {category.icon && Icons[category.icon as IconName] ? (
              React.createElement(Icons[category.icon as IconName])
            ) : (
              <Edit />
            )}
          </IconButton>
          {isEditing ? (
            <TextField
              value={category.name}
              onChange={e => setCategoryTitle(e.target.value)}
              variant='outlined'
              size='small'
            />
          ) : (
            <Typography variant='h6'>
              {category.name || 'Unnamed Category'}
            </Typography>
          )}
        </Box>
        <Box>
          <TextField
            type='number'
            label='Weight'
            value={category.weight}
            onChange={e => setCategoryWeight(Number(e.target.value))}
            variant='standard'
            size='small'
            sx={{
              width: 100,
              marginRight: 2,
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiInput-underline:before': { borderBottomColor: 'white' },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: 'white',
              },
              '& .MuiInputLabel-root': { color: 'white' },
            }}
            inputProps={{ min: 0, max: 1, step: 0.1 }}
          />
          <IconButton
            onClick={() => setIsEditing(!isEditing)}
            sx={{ color: 'white' }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => deleteCategory(category.name)}
            sx={{ color: 'white' }}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
      <CardContent sx={{ border: 'none', p: 2, backgroundColor: '#EFF0FA' }}>
        <Rules
          rules={category.rules}
          setRules={buildNestedSetState(setCategory, 'rules')}
        />
        <Button
          variant='outlined'
          startIcon={<Add />}
          onClick={newRule}
          sx={{ marginTop: 2 }}
        >
          New Rule
        </Button>
      </CardContent>
      <IconPicker
        open={showIconPicker}
        onClose={() => setShowIconPicker(false)}
        onSelectIcon={handleIconSelect}
      />
    </Card>
  );
};
const cellStyle: React.CSSProperties = {
  verticalAlign: 'top',
  textAlign: 'left',
  border: 'none',
};

interface RulesProps {
  rules: IRule<SimpleType | ArrayType>[];
  setRules: React.Dispatch<
    React.SetStateAction<IRule<SimpleType | ArrayType>[]>
  >;
}

const Rules: React.FC<RulesProps> = ({ rules, setRules }) => {
  const deleteRule = (index: number) => {
    setRules(prevRules => prevRules.filter((_, i) => i !== index));
  };

  return (
    <TableContainer
      component={Paper}
      variant='outlined'
      sx={{ backgroundColor: 'inherit', border: 'none' }}
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell style={cellStyle}>Category</TableCell>
            <TableCell style={cellStyle}>Field</TableCell>
            <TableCell style={cellStyle}>Operator</TableCell>
            <TableCell style={cellStyle}>Value</TableCell>
            <TableCell style={cellStyle}>Weight</TableCell>
            <TableCell style={cellStyle}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rules.map((rule, i) => (
            <Rule
              key={i}
              rule={rule}
              setRule={buildNestedSetState(setRules, i)}
              onDelete={() => deleteRule(i)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface RuleProps {
  rule: IRule<SimpleType | ArrayType>;
  setRule: React.Dispatch<React.SetStateAction<IRule<SimpleType | ArrayType>>>;
  onDelete: () => void;
}

const Rule: React.FC<RuleProps> = ({ rule, setRule, onDelete }) => {
  const { availableCds } = useCategories();
  if (!rule.cds) return null;
  const { type } = rule.cds.structure;
  const possibleOperations = getAvailableOperations(
    type as SimpleType | ArrayType,
  );

  const setOperation = (operation: IOperation<SimpleType | ArrayType>) => {
    setRule(prev => ({ ...prev, operation }));
  };

  const setOpByName = (name: OperationName<SimpleType | ArrayType>) => {
    const newOperation = buildOperation(
      type as SimpleType | ArrayType,
      name as OperationName<SimpleType | ArrayType>,
    );
    setOperation(newOperation);
  };

  const filteredTitles = availableCds
    .filter(cds => cds.category === rule.cds.category)
    .map(cds => cds.title);

  function setCdsCategory(category: string) {
    const cdsInCategory = availableCds.filter(cds => cds.category === category);
    if (cdsInCategory.length > 0) {
      const firstCdsInCategory = cdsInCategory[0];

      const newType = type;
      const newOperation = buildOperation(
        newType,
        getDefaultOperator(newType) as OperationName<typeof newType>,
      );
      setRule(prev => ({
        ...prev,
        cds: firstCdsInCategory,
        operation: newOperation,
      }));
    }
  }

  function setCdsByTitle(title: string) {
    const cds = availableCds.find(cds => cds.title === title)!;
    const newType = cds.structure.type;
    const newOperation = buildOperation(
      newType,
      getDefaultOperator(newType) as OperationName<typeof newType>,
    );
    setRule(prev => ({
      ...prev,
      cds,
      operation: newOperation,
    }));
  }

  const setWeight = buildNestedSetState(setRule, 'weight');

  const renderValueInput = () => {
    const { operation } = rule;

    const setOperation = buildNestedSetState(setRule, 'operation');
    const setParams = buildNestedSetState(setOperation, 'params');

    let o;
    switch (type) {
      case 'boolean':
        o = operation as IOperation<'boolean'>;
        return (
          <Switch
            checked={o.params.target}
            onChange={e =>
              setParams(prev => ({
                ...prev,
                target: e.target.checked,
              }))
            }
          />
        );
      case 'date':
        o = operation as IOperation<'date'>;
        switch (operation.name) {
          case 'btw':
            return (
              <>
                <DatePicker
                  label='Min'
                  value={o.params.min}
                  onChange={date =>
                    setParams(prev => ({
                      ...prev,
                      min: date,
                    }))
                  }
                />
                <DatePicker
                  label='Max'
                  value={o.params.max}
                  onChange={date =>
                    setParams(prev => ({
                      ...prev,
                      max: date,
                    }))
                  }
                />
              </>
            );
          default:
            return (
              <DatePicker
                value={o.params.target}
                onChange={date =>
                  setParams(prev => ({
                    ...prev,
                    target: date,
                  }))
                }
              />
            );
        }
      case 'number':
        o = operation as IOperation<'number'>;
        switch (operation.name) {
          case 'btw':
            return (
              <>
                <TextField
                  type='number'
                  label='Min'
                  value={o.params.min}
                  onChange={e =>
                    setParams(prev => ({
                      ...prev,
                      min: Number(e.target.value),
                    }))
                  }
                  variant='standard'
                  size='small'
                />
                <TextField
                  type='number'
                  label='Max'
                  value={o.params.max}
                  onChange={e =>
                    setParams(prev => ({
                      ...prev,
                      max: Number(e.target.value),
                    }))
                  }
                  variant='standard'
                  size='small'
                />
              </>
            );
          default:
            return (
              <TextField
                type='number'
                value={o.params.target}
                onChange={e =>
                  setParams(prev => ({
                    ...prev,
                    target: Number(e.target.value),
                  }))
                }
                variant='standard'
                size='small'
              />
            );
        }
      case 'string':
      case 'array':
      default:
        o = operation as IOperation<'string' | 'array'>;
        switch (operation.name) {
          case 'notEmpty':
          case 'empty':
            return null;
          case 'length':
          case 'minLength':
          case 'maxLength':
            o = operation as IOperation<
              'array',
              'length' | 'minLength' | 'maxLength'
            >;
            return (
              <TextField
                type='number'
                value={o.params.target}
                onChange={e =>
                  setParams(prev => ({
                    ...prev,
                    target: Number(e.target.value),
                  }))
                }
                variant='standard'
                size='small'
              />
            );
          default:
            o = operation as IOperation<'string' | 'array'>;
            return (
              <TextField
                value={(o.params as any).target}
                onChange={e =>
                  setParams(prev => ({
                    ...prev,
                    target: e.target.value,
                  }))
                }
                variant='standard'
                size='small'
                fullWidth
              />
            );
        }
    }
  };

  const valid = validateRule(rule);

  return (
    <TableRow
      sx={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: valid ? 'transparent' : 'red',
      }}
    >
      <TableCell style={cellStyle}>
        <NormalizedSelect
          value={rule.cds.category}
          options={Array.from(new Set(availableCds.map(cds => cds.category)))}
          onChange={setCdsCategory}
          noAll
        />
      </TableCell>
      <TableCell style={cellStyle}>
        <NormalizedSelect
          value={rule.cds.title}
          options={filteredTitles}
          onChange={setCdsByTitle}
          noAll
        />
      </TableCell>
      <TableCell style={cellStyle}>
        <NormalizedSelect
          value={rule.operation.name}
          options={possibleOperations}
          onChange={setOpByName}
          noAll
        />
      </TableCell>
      <TableCell style={cellStyle}>{renderValueInput()}</TableCell>
      <TableCell style={cellStyle}>
        <TextField
          type='number'
          value={rule.weight}
          onChange={e => setWeight(Number(e.target.value))}
          variant='standard'
          size='small'
          inputProps={{ min: 0, max: 1, step: 0.1 }}
          sx={{ width: 80 }}
        />
      </TableCell>
      <TableCell style={cellStyle}>
        <IconButton onClick={onDelete} sx={{ color: '#4c4c7c' }}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

function getDefaultOperator<T extends SimpleType | ArrayType>(
  type: T,
): OperationName<T> {
  switch (type) {
    case 'array':
      return 'includes' as OperationName<T>;
    case 'number':
    case 'date':
      return 'gte' as OperationName<T>;
    case 'boolean':
      return 'eq' as OperationName<T>;
    case 'string':
    default:
      return 'contains' as OperationName<T>;
  }
}
