import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { PresenceItem } from '../../models/creativeData';
import { getColor } from '../../theme/colors';
import dates from '../../utils/dates';
import { AvatarWithTooltip } from '../Avatar';

const avatarSize = 40;
const avatarMr = 10;
const avatarTotal = avatarSize + avatarMr;
const markersTargetDistance = 3; // in seconds

interface PresenceTimelineProps {
  data: PresenceItem[];
  metadata?: { videoDuration: number };
  controls?: {
    setSeekTime?: (time: number) => void;
  };
}
const PresenceTimeline: React.FC<PresenceTimelineProps> = ({
  data,
  metadata,
  controls,
}) => {
  const presences = data as PresenceItem[];

  const totalDuration =
    metadata?.videoDuration ||
    presences.reduce((acc, item) => {
      if (Array.isArray(item.appearances) && item.appearances.length > 0) {
        const maxEndTime = Math.max(
          ...item.appearances.map(period =>
            dates.parseTimestamp(period.timeRange.end),
          ),
        );
        return Math.max(acc, maxEndTime);
      }
      return acc;
    }, 0);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return [hours, minutes, remainingSeconds]
      .map(unit => unit.toString().padStart(2, '0'))
      .join(':');
  };

  const markersCount = totalDuration
    ? Math.min(Math.floor(totalDuration / markersTargetDistance) + 1, 5)
    : 0;
  const timeMarkers = Array.from({ length: markersCount }, (_, i) =>
    Math.round((i * totalDuration) / (markersCount - 1)),
  );

  return (
    <ChartContainer>
      <Chart>
        {presences.map((item, i) => (
          <ItemRow key={i}>
            <AvatarWithTooltip
              src={item.image}
              alt={item.label as string}
              width={avatarSize}
              height={avatarSize}
              marginRight={`${avatarMr}px`}
            />
            <Timeline>
              {Array.isArray(item.appearances) &&
                item.appearances.map((period, j) => (
                  <Tooltip
                    key={j}
                    title={`${dates.parseTimestamp(
                      period.timeRange.start,
                    )} - ${dates.parseTimestamp(period.timeRange.end)}`}
                    arrow
                  >
                    <PresencePeriod
                      start={
                        (dates.parseTimestamp(period.timeRange.start) /
                          totalDuration) *
                        100
                      }
                      end={
                        (dates.parseTimestamp(period.timeRange.end) /
                          totalDuration) *
                        100
                      }
                      color={getColor(i)}
                      onClick={() =>
                        controls?.setSeekTime?.(
                          dates.parseTimestamp(period.timeRange.start),
                        )
                      }
                    />
                  </Tooltip>
                ))}
            </Timeline>
          </ItemRow>
        ))}
      </Chart>
      {presences.length > 0 && (
        <XAxis sx={{ marginBottom: '20px' }}>
          {timeMarkers.map(time => (
            <TimeMarker
              key={time}
              variant='caption'
              pos={(time / totalDuration) * 100}
            >
              {formatTime(time)}
            </TimeMarker>
          ))}
        </XAxis>
      )}
    </ChartContainer>
  );
};
export default PresenceTimeline;

const ChartContainer = styled(Box)(() => ({ marginRight: 20 }));

const Chart = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const ItemRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
});

const Timeline = styled(Box)({
  flexGrow: 1,
  height: 20,
  backgroundColor: '#EBEBEB',
  position: 'relative',
  borderRadius: 15,
});

const PresencePeriod = styled(Box)<{
  start: number;
  end: number;
  color: string;
}>(({ start, end, color }) => ({
  position: 'absolute',
  height: '100%',
  backgroundColor: color,
  left: `${start}%`,
  width: `${end - start}%`,
  borderRadius: 15,
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const XAxis = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 5,
  marginLeft: avatarTotal,
  position: 'relative',
});

const TimeMarker = styled(Typography)<{ pos: number }>(({ pos }) => ({
  position: 'absolute',
  left: `${pos}%`,
  transform: 'translateX(-50%)',
}));
