import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingRessourcePage from '../../components/LoadingRes';
import { useAuthenticatedUser } from '../../contexts/auth';
import { AnalyzedVideo, IPreflightVideo, isAnalyzedVideo } from '../../models';
import Creatives from './Creatives';
import Metadata from './Metadata';
import { VideoDashboardProvider } from './context';

const VideoDashboard: React.FC = () => {
  const { id } = useParams();
  if (!id) throw new Error('No ID provided');
  const { api } = useAuthenticatedUser();
  const [video, setVideo] = useState<AnalyzedVideo>();
  useEffect(() => {
    api.videos.one(id).then(setVideo);
  }, [api, id]);
  if (!video) return <LoadingRessourcePage resName='Analysis' />;

  return (
    <VideoDashboardProvider video={video}>
      <InnerVideoDashboard />
    </VideoDashboardProvider>
  );
};
export default VideoDashboard;

export const PreflightVideoDashboard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error('No id provided');
  const { api } = useAuthenticatedUser();
  const [preflight, setPreflight] = useState<IPreflightVideo | null>(null);
  useEffect(() => {
    api.preflight.one(id).then(setPreflight);
  }, [api, id]);
  if (!preflight) return <LoadingRessourcePage resName='Preflight' />;
  if (!isAnalyzedVideo(preflight))
    throw new Error('Preflight video is not analyzed');

  return (
    <VideoDashboardProvider video={preflight}>
      <InnerVideoDashboard />
    </VideoDashboardProvider>
  );
};

const InnerVideoDashboard: React.FC = () => {
  return (
    <Box>
      <Metadata />

      <Creatives />
    </Box>
  );
};
