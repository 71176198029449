import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import MinimalistChart from '../../components/Charts/MinimalistChart';
import { IGuidelineScore } from '../../models/guidelines';
import { getColor } from '../../theme/colors';
import { DynamicIcon } from '../General/IconPicker';

interface GuidelinesScoreProps {
  platform?: string;
  guidelineScore: IGuidelineScore;
  historyData?: DynamicArray;
  variant?: 'default' | 'small';
}
type DynamicArray = {
  [key: string]: number[];
};

const GuidelinesScore: React.FC<GuidelinesScoreProps> = ({
  guidelineScore,
  platform,
  historyData,
  variant = 'default',
}) => {
  const dataset = guidelineScore.scores
    .map(({ score, ...rest }) => ({
      score: score * 100,
      ...rest,
    }))
    .sort((a, b) => a.score - b.score); // Sort by score in descending order

  const isSmall = variant === 'small';

  return (
    <Stack
      direction='row'
      spacing={isSmall ? 1 : 2}
      alignItems='center'
      width={'100%'}
    >
      <Stack
        direction='row'
        spacing={isSmall ? 4 : 8}
        alignItems='center'
        width={'100%'}
      >
        <Stack width='100%'>
          {!isSmall && (
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={2}
              justifyContent={'space-between'}
            >
              {!platform && (
                <>
                  <Typography variant='body2'>Brand Guidelines</Typography>
                  <DynamicIcon
                    iconName={'Storefront'}
                    style={{ color: '#a9a9a9', width: '20px', height: '30px' }}
                  />
                </>
              )}
              {platform && (
                <>
                  <Typography variant='body2'>Paltforms Guidelines</Typography>
                  <DynamicIcon
                    iconName={'Share'}
                    style={{ color: '#a9a9a9', width: '20px', height: '30px' }}
                  />
                </>
              )}
            </Box>
          )}
          <Box
            position='relative'
            display='flex'
            justifyContent='center'
            alignItems='center'
            height={isSmall ? 80 : 150}
            mt={isSmall ? 0 : 1}
          >
            <Stack
              direction='column'
              spacing={2}
              alignItems='center'
              width={'50%'}
            >
              <Box display='flex' alignItems='center' width={'100%'}>
                {
                  <Typography
                    sx={{
                      position: 'absolute',
                      fontWeight: isSmall ? '500' : 'bold',
                      color: isSmall ? '#666' : 'text.primary',
                      fontSize: isSmall ? '0.7rem' : '1.7rem',
                      width: isSmall ? 50 : 'auto',
                    }}
                  >
                    {isSmall
                      ? platform
                        ? 'Platform Score'
                        : 'Brand Score'
                      : (guidelineScore.totalScore * 100).toFixed(0) + '%'}
                  </Typography>
                }
              </Box>
              {historyData && !isSmall && (
                <Stack height={100} width={'100%'}>
                  <MinimalistChart data={historyData['Avg']} />
                </Stack>
              )}
            </Stack>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              width={'50%'}
            >
              <Tooltip
                title={
                  <Box>
                    {dataset.map((item, index) => (
                      <Box
                        key={item.name}
                        display='flex'
                        alignItems='center'
                        gap={1}
                        p={1}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: getColor(index),
                          }}
                        />
                        {item.icon && (
                          <DynamicIcon
                            iconName={item.icon}
                            style={{ width: '20px', height: '20px' }}
                          />
                        )}
                        <Typography variant='body2'>
                          {item.name} - {Math.round(item.score)}%
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                }
                placement='right'
                followCursor
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: isSmall ? '150px' : '300px',
                    width: isSmall ? '150px' : '300px',
                    gap: isSmall ? 1 : 2,
                    overflow: 'visible',
                  }}
                >
                  {isSmall && (
                    <Typography
                      sx={{
                        position: 'absolute',
                        fontWeight: 'bold',
                        color: '#666',
                        fontSize: '0.7rem',
                        zIndex: 1,
                      }}
                    >
                      {(guidelineScore.totalScore * 100).toFixed(0)}%
                    </Typography>
                  )}
                  {dataset.map((item, index) => (
                    <Box
                      key={item.name}
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress
                        variant='determinate'
                        value={100}
                        size={isSmall ? 95 - index * 20 : 150 - index * 30}
                        thickness={isSmall ? 4 : 6}
                        sx={{
                          position: 'absolute',
                          color: '#ebebeb',
                          '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round',
                            strokeWidth: isSmall ? '4px' : '6px',
                            strokeDasharray: '2 1',
                          },
                        }}
                      />
                      <CircularProgress
                        variant='determinate'
                        value={item.score}
                        size={isSmall ? 95 - index * 20 : 150 - index * 30}
                        thickness={isSmall ? 4 : 6}
                        sx={{
                          position: 'absolute',
                          color: getColor(index),
                          '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round',
                            strokeWidth: isSmall ? '4px' : '6px',
                            strokeDasharray: '2 1',
                          },
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Tooltip>
            </Stack>
          </Box>

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {dataset.map((item, index) => (
              <Box
                key={item.name}
                sx={{
                  position: 'absolute',
                  width: isSmall ? 75 - index * 10 : 150 - index * 20,
                  height: isSmall ? 75 - index * 10 : 150 - index * 20,
                  borderRadius: '50%',
                }}
              />
            ))}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GuidelinesScore;
