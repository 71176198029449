import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { AnalyzedVideo } from '../models';

interface EmbeddedVideoProps {
  video: AnalyzedVideo;
  height?: string | number;
  seekTime?: number;
  scrollToOnSeek?: boolean;
}

const EmbeddedVideo: React.FC<EmbeddedVideoProps> = props => {
  const { video, seekTime = 0, scrollToOnSeek = false } = props;
  const playerRef = useRef<ReactPlayer>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (ready) {
      playerRef.current?.seekTo(seekTime, 'seconds');
      setPlaying(true);
    }
    if (scrollToOnSeek) boxRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [seekTime, ready, scrollToOnSeek]);

  return (
    <Box
      ref={boxRef}
      sx={{
        borderRadius: '20px',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <ReactPlayer
        ref={playerRef}
        url={video.metadata.videoUrl}
        playing={playing}
        controls={true}
        width='100%'
        height='auto'
        onReady={() => setReady(true)}
      />
    </Box>
  );
};

export default EmbeddedVideo;
