import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import CreativeDataCards from '../../../components/CreativeDataCards';
import { DetailsListComponent } from '../../../components/CreativeDataComponent';
import { isSimpleType } from '../../../models/creativeDataStructure';

import EmbeddedVideo from '../../../components/EmbeddedVideo';
import useVideoDashboard from '../context';
import PreflightEvaluation from '../PreflightEvaluation';
import useCreatives, { CreativesProvider } from './context';

const Creatives: React.FC = () => {
  return (
    <CreativesProvider>
      <Stack
        p={2}
        mb={2}
        sx={{
          borderRadius: '20px',
        }}
      >
        <Grid item xs={8}></Grid>
        <Results />
      </Stack>
    </CreativesProvider>
  );
};

export default Creatives;

const Results: React.FC = () => {
  const { video } = useVideoDashboard();
  const { selectedCd, creativeData } = useCreatives();
  const { setSeekTime, seekTime } = useVideoDashboard();

  let detailList = selectedCd
    .filter(cds => !cds.highlighted && isSimpleType(cds.structure))
    .sort((a, b) => a.order - b.order);

  let hilightedCD = selectedCd.filter(
    cds => cds.highlighted || !isSimpleType(cds.structure),
  );
  return (
    <Box py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={4}>
          <Stack
            sx={{
              display: 'flex',
              borderRadius: '20px',
              position: 'relative',
              height: '100%',
              flexDirection: 'row',
              p: 2,
              gap: 5,
              background:
                'linear-gradient(180deg, #EDEFFD 0%, #F6F5EE 47%, #F7F5ED 100%)',
            }}
          >
            <Box sx={{ width: '50%' }}>
              <EmbeddedVideo video={video} seekTime={seekTime} />
            </Box>
            <Box sx={{ width: '50%' }}>
              <DetailsListComponent
                filteredCds={detailList}
                creativeData={creativeData}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {video.type === 'preflight' && <PreflightEvaluation video={video} />}
        </Grid>

        <CreativeDataCards
          selectedCd={hilightedCD}
          creativeData={creativeData}
          controls={{ setSeekTime }}
        />
      </Grid>
    </Box>
  );
};
