// Imports
import { Box, Pagination, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

// Component imports
import PresenceSummaryChart from '../components/Charts/PresenceSummaryChart';
import { getNestedValue } from '../utils/misc';
import ColorWheelChart from './Charts/ColorWheelChart';
import CustomTagCloud from './Charts/CustomTagCloud';
import PositionMatrix from './Charts/PositionMatrix';
import PresenceTimeline from './Charts/PresenceTimeline';
import SpiderChart from './Charts/SpiderChart';
import SimpleBarChart from './Charts/StackedBarChart';
import StackedGaugeChart from './Charts/StackedGaugeChart';
import WorldMapChart from './Charts/WorldMapChart';

// Utility imports
import { DynamicIcon } from '../features/General/IconPicker';
import {
  getMappedValue,
  getSFormatter,
  simpleUiTypes,
} from '../models/creativeDataStructure/typeMaps';

// Type imports
import { ICreativeData } from '../models/creativeData';
import {
  DataType,
  ICreativeDataStructure,
  IStructure,
  SimpleType,
  UiType,
} from '../models/creativeDataStructure';
import { cdIsEmptyOrNull } from '../utils/misc';

export interface CdComponentControls {
  setSeekTime?: (time: number) => void;
}

// Type definitions
export type CdComponentProps<U extends UiType = UiType> = {
  data: DataType<U>;
  structure: IStructure<U>;
  metadata?: ICreativeData['general'];
  controls?: Partial<CdComponentControls>;
};

export type CdComponent<U extends UiType> = React.FC<CdComponentProps<U>>;

// Simple components
export const SimpleComponent: CdComponent<UiType<SimpleType>> = ({
  data,
  structure,
}) => (
  <Typography fontSize={80} fontWeight={600}>
    {getSFormatter(structure.uiType)(data)}
  </Typography>
);

export const SimpleList: CdComponent<'simpleList'> = ({ data }) => {
  if (Array.isArray(data)) {
    return (
      <Stack>
        {data.map((item, i) => (
          <Typography key={i} fontSize={80} fontWeight={600}>
            {item && item.toString()}
          </Typography>
        ))}
      </Stack>
    );
  }
  return (
    <Typography fontSize={80} fontWeight={600}>
      Selected Property is not an Array
    </Typography>
  );
};

// DetailItem component
const DetailItem: React.FC<{
  icon: string;
  title: string;
  value: any;
  uiType: UiType<SimpleType>;
}> = ({ icon, title, value, uiType }) => {
  const formatter = getSFormatter(uiType);

  return (
    <Box
      display='flex'
      alignItems='flex-start'
      justifyContent='space-between'
      p={1}
      gap={2}
      flexDirection='row'
      flexWrap={'wrap'}
      borderBottom='1px solid #e0e0e0'
    >
      <Box display='flex' alignItems='center' gap={1}>
        <DynamicIcon iconName={icon} />
        <Typography variant='body2' color='text.secondary'>
          {title}
        </Typography>
      </Box>
      <Typography variant='body2' fontWeight='medium'>
        {formatter(value)}
      </Typography>
    </Box>
  );
};

export const DetailsListComponent: React.FC<{
  filteredCds: ICreativeDataStructure[];
  creativeData: Record<string, any>;
}> = ({ filteredCds, creativeData }) => {
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 12; // Increased from 8 to 12 for better grid layout
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const sortedCds = filteredCds.sort((a, b) => (a.order || 0) - (b.order || 0));
  const totalPages = Math.ceil(sortedCds.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPageItems = sortedCds.slice(startIndex, endIndex);

  return (
    <>
      <Box
        sx={{
          gap: 2,
          '& > div': {
            breakInside: 'avoid',
            marginBottom: 2,
          },
        }}
      >
        {currentPageItems.map((cds, i) => (
          <DetailItem
            key={i}
            icon={cds.icon || ''}
            title={cds.title}
            value={
              !cdIsEmptyOrNull(getNestedValue(creativeData, cds.source))
                ? getNestedValue(creativeData, cds.source)
                : 'N/A'
            }
            uiType={cds.structure.uiType as UiType<SimpleType>}
          />
        ))}
      </Box>
      {totalPages > 1 && (
        <Box display='flex' justifyContent='center' mt={3}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color='primary'
          />
        </Box>
      )}
    </>
  );
};

// Registered components
const registeredComponents: {
  [key in UiType]?: CdComponent<key>;
} = {
  presences: PresenceTimeline,
  tagCloud: CustomTagCloud as CdComponent<'tagCloud'>,
  stackedChart: StackedGaugeChart as CdComponent<'stackedChart'>,
  presenceSummary: PresenceSummaryChart,
  positionMatrix: PositionMatrix as CdComponent<'positionMatrix'>,
  colorPalette: ColorWheelChart as CdComponent<'colorPalette'>,
  barChart: SimpleBarChart as CdComponent<'barChart'>,
  worldMapChart: WorldMapChart as CdComponent<'worldMapChart'>,
  spiderChart: SpiderChart as CdComponent<'spiderChart'>,
  simpleList: SimpleList as CdComponent<'simpleList'>,
};

// Register simple UI types
for (const type of simpleUiTypes) {
  if (!registeredComponents[type]) {
    registeredComponents[type] = SimpleComponent as any;
  }
}

// NotAvailable component
const NotAvailable: React.FC<{ name: string }> = ({ name }) => (
  <Box>{name} not available</Box>
);

// Main CreativeDataComponent
export const CreativeDataComponent: CdComponent<UiType> = ({
  structure,
  data,
  metadata,
  controls,
}) => {
  if (structure) {
    if (!data) return <NotAvailable name='data' />;
    const Component = registeredComponents[structure.uiType];
    if (!Component) return <NotAvailable name={structure.uiType} />;
    const mappedData = getMappedValue(structure, data) as any[];

    const updatedProps = {
      structure,
      data: mappedData,
      metadata: metadata,
    };
    return <Component {...(updatedProps as any)} controls={controls} />;
  }
  return <NotAvailable name='structure' />;
};

export default CreativeDataComponent;
