import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthenticatedUser } from '../../../contexts/auth';
import { Platform, Source } from '../../../models';
import IInsight from '../../../models/insights';

// year to date
const defaultStartDate = new Date(new Date().getFullYear(), 0, 1);
const defaultEndDate = new Date();

interface Filters {
  platform?: Platform;
  brand?: string;
  country?: string;
  source?: Source;
  start?: Date;
  end?: Date;
}

interface AvailableFilters {
  platforms: Platform[];
  brands: string[];
  countries: string[];
}

interface InsightContextType {
  filters: Filters;
  availableFilters: AvailableFilters;
  setFilter: <K extends keyof Filters>(key: K) => (value: Filters[K]) => void;
  insights: IInsight[];
  setInsights: React.Dispatch<React.SetStateAction<IInsight[]>>;
  loading: boolean;
  calculateInsights: () => void;
}

const InsightContext = createContext<InsightContextType | null>(null);

export const InsightProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { api } = useAuthenticatedUser();
  const [filters, setFilters] = useState<Filters>({
    start: defaultStartDate,
    end: defaultEndDate,
  });
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>({
    platforms: [],
    brands: [],
    countries: [],
  });
  const [insights, setInsights] = useState<IInsight[]>([]);
  const [loading, setLoading] = useState(false);

  const setFilter = useCallback(
    <K extends keyof Filters>(key: K) =>
      (value: Filters[K]) =>
        setFilters(f => ({ ...f, [key]: value })),
    [],
  );

  useEffect(() => {
    api.videos
      .platforms({ analyzed: true })
      .then(platforms => setAvailableFilters(af => ({ ...af, platforms })));
  }, [api]);

  useEffect(() => {
    api.videos
      .brands({ platform: filters.platform, analyzed: true })
      .then(brands => setAvailableFilters(af => ({ ...af, brands })));
  }, [api, filters.platform]);

  useEffect(() => {
    api.videos
      .countries({
        brand: filters.brand,
        platform: filters.platform,
        analyzed: true,
      })
      .then(countries => setAvailableFilters(af => ({ ...af, countries })));
  }, [api, filters.brand, filters.platform]);

  const calculateInsights = useCallback(() => {
    api.insights
      .getInsights(filters)
      .then(setInsights)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [api, filters]);

  const validInsights = insights.filter(insight => {
    const [factorValue] = insight.content.match(/\d+(\.\d+)?/) || [];
    return factorValue && parseFloat(factorValue) > 0;
  });

  const value: InsightContextType = {
    filters,
    setFilter,
    availableFilters,
    insights: validInsights,
    setInsights,
    loading,
    calculateInsights,
  };

  return (
    <InsightContext.Provider value={value}>{children}</InsightContext.Provider>
  );
};

export const useInsight = () => {
  const context = useContext(InsightContext);
  if (!context) {
    throw new Error('useInsights must be used within an InsightProvider');
  }
  return context;
};
