import styled from '@emotion/styled';
import {
  AccountTreeOutlined,
  AutoGraph,
  AvTimerOutlined,
  BookmarkBorderOutlined,
  Fitbit,
  FlightTakeoffOutlined,
  Instagram,
  Menu as MenuIcon,
  Person,
  ScienceOutlined,
  SpaceDashboardOutlined,
  SupervisorAccount,
  TipsAndUpdates,
  VideoLibraryOutlined,
  ViewCompact,
  ViewWeek,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import logo from '../../assets/logo.svg';
import { useAuthenticatedUser } from '../../contexts/auth';
import SidebarItem from './SidebarItem';

const SidePanel: React.FC<{ width: number | string }> = ({ width }) => {
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

  const toggleMenu = (menuKey: string) => {
    setOpenMenus(prev => ({ ...prev, [menuKey]: !prev[menuKey] }));
  };

  return (
    <Drawer
      variant='permanent'
      sx={{
        width,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width,
          boxSizing: 'border-box',
          background: theme => theme.palette.gradient.vertical,
          borderRight: 'none',
          borderTopRightRadius: '30px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: '50px auto 0px auto',
        }}
      >
        <img src={logo} alt='Logo' style={{ width: '60%', height: 'auto' }} />
      </Box>
      <Box sx={{ overflow: 'auto' }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            margin: '0 20px',
            padding: '50px 0',
            borderBottom: '1px solid white',
          }}
        >
          <SidebarItem
            icon={<SpaceDashboardOutlined />}
            title='Dashboard'
            to='/dashboard'
          />
          <SidebarItem
            icon={<VideoLibraryOutlined />}
            title='Creative Library'
            to='/library'
          />
          <SidebarItem
            icon={<AutoGraph />}
            title='Asset iQ'
            to='/InsightSpotter'
          />
          <SidebarItem
            icon={<AvTimerOutlined />}
            title='Moments'
            to='/Moments'
          />
          <SidebarItem
            icon={<ScienceOutlined />}
            title='Experiments'
            to='/Experiments'
          />
          <SidebarItem
            icon={<FlightTakeoffOutlined />}
            title='Pre-Flight'
            to='/preflights'
          />
          <GroupDivider />
          <SidebarItem
            title='Indexation'
            icon={<ViewCompact />}
            role={'ADMIN'}
            type='group'
            open={openMenus['indexation']}
            onToggle={() => toggleMenu('indexation')}
          >
            <SidebarItem
              icon={<AccountTreeOutlined />}
              title='Structures'
              to='/admin/structures'
              role={'ADMIN'}
              type='sub'
            />
            <SidebarItem
              icon={<ViewWeek />}
              title='Virtual Fields'
              type='sub'
              to='admin/calculatedFields'
            />
          </SidebarItem>
          <GroupDivider />
          <SidebarItem
            icon={<MenuIcon />}
            title='Guidelines'
            role={'ADMIN'}
            type='group'
            open={openMenus['guidelines']}
            onToggle={() => toggleMenu('guidelines')}
          >
            <SidebarItem
              icon={<Fitbit />}
              title='Brands Guidelines'
              to='/admin/guidelines/brand'
              type='sub'
              role={'ADMIN'}
            />
            <SidebarItem
              icon={<Instagram />}
              title='Platform Guidelines'
              to='/admin/guidelines/platform'
              type='sub'
              role={'ADMIN'}
            />
          </SidebarItem>
          <GroupDivider />
          <SidebarItem
            icon={<SupervisorAccount />}
            title='Users'
            to='admin/users'
            role={'ADMIN'}
          />
          <GroupDivider />
          <SidebarItem
            icon={<TipsAndUpdates />}
            title='Insights'
            type='group'
            open={openMenus['insights']}
            onToggle={() => toggleMenu('insights')}
          >
            <SidebarItem
              icon={<BookmarkBorderOutlined />}
              title='Bookmarked Insights'
              to='/BookmarkedInsights'
              type='sub'
            />
            <SidebarItem
              icon={<Fitbit />}
              title='Brands Insights'
              to={undefined}
              type='sub'
            />
            <SidebarItem
              icon={<Instagram />}
              title='Platform Insights'
              to={undefined}
              type='sub'
            />
          </SidebarItem>
        </List>
        <ProfileBadge />
      </Box>
    </Drawer>
  );
};

export default SidePanel;

const ProfileBadge: React.FC = () => {
  const { user, logout } = useAuthenticatedUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'left', m: '25px 35px' }}>
      <Button
        sx={{ display: 'flex', textTransform: 'none', color: 'inherit' }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <Avatar
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.54)',
            width: 31,
            height: 31,
            fontSize: '1rem',
          }}
        >
          <Person />
        </Avatar>
        <Typography
          variant='body2'
          sx={{ marginLeft: '10px', fontSize: '14px', letterSpacing: '1px' }}
        >
          {user.Username}
        </Typography>
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        keepMounted
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        slotProps={{ paper: { sx: { borderRadius: '8px' } } }}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

const GroupDivider = styled(Divider)({
  margin: '10px',
  border: '1px solid #fff',
});
