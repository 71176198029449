import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import banner from '../../assets/exp-banner.svg';
import logo from '../../assets/exp-logo.svg';
import CenteredSvg from '../../components/CenteredSvg';
import Moments from '../Moments';
import Opera from '../Opera';
import ExperimentsBox from './ExperimentsBox';
import PartnershipChecker from './PartnershipChecker';

const ExperimentsPage: React.FC = () => {
  return (
    <Stack height='100%'>
      <CenteredSvg src={logo} alt='Experiments Logo' />
      <Box flexGrow={1} minHeight={0}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route
            path='/partnership-checker/*'
            element={<PartnershipChecker />}
          />
          <Route path='/Opera/*' element={<Opera />} />
          <Route path='/Moments/*' element={<Moments />} />
        </Routes>
      </Box>
    </Stack>
  );
};

const Home: React.FC = () => {
  return (
    <Box display='flex' flexDirection='column' height='100%' overflow='auto'>
      <CenteredSvg src={banner} alt='Experiments Banner' boxProps={{ my: 2 }} />
      <Box display='flex' justifyContent='center'>
        <Button
          style={{
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '20px',
            padding: '10px 20px',
            border: 'none',
          }}
        >
          Suggest an Idea
        </Button>
      </Box>
      <Box flexGrow={1}>
        <ExperimentsBox />
      </Box>
    </Box>
  );
};

export default ExperimentsPage;
