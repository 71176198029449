import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { Role, User, WithPerms, WithRoles } from '../../models/users';
import { Api } from '../../services/api';

const UsersMgrContext = createContext<UsersMgrContextType | null>(null);

export const UsersMgrProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const [users, setUsers] = useState<WithPerms<WithRoles<User>>[]>([]);
  const [roles, setRoles] = useState<WithPerms<Role>[]>([]);

  useEffect(() => {
    api.users.all().then(setUsers);
    api.roles.all().then(setRoles);
  }, [api]);

  const getRole = useCallback(
    (RoleID: number) => roles.find(r => r.RoleID === RoleID),
    [roles],
  );

  const getPermsForRoles = useCallback(
    (inputRoles: Role[]) =>
      roles
        .filter(r => inputRoles.some(ir => ir.RoleID === r.RoleID))
        .map(r => r.Permissions)
        .flat()
        .filter(
          (curr, i, arr) =>
            arr.findIndex(p => p.PermissionID === curr.PermissionID) === i,
        ),
    [roles],
  );

  const createUser = useCallback(
    (user: CreateUserPayload) =>
      api.users.create(user).then(res => setUsers(prev => [...prev, res])),
    [api],
  );
  const editUser = useCallback(
    (user: WithRoles<User>) =>
      api.users.update(user).then(res => {
        const updatedUser = {
          ...res,
          Roles: user.Roles,
          Permissions: getPermsForRoles(user.Roles),
        };
        setUsers(prev =>
          prev.map(u => (u.UserID === user.UserID ? updatedUser : u)),
        );
        return updatedUser;
      }),
    [api, getPermsForRoles],
  );
  const deleteUser = useCallback(
    (UserID: string | number) =>
      api.users.delete(Number(UserID)).then(deleteUser => {
        setUsers(prev => prev.filter(u => u.UserID !== deleteUser.UserID));
        return deleteUser;
      }),
    [api],
  );

  const value = { users, roles, getRole, createUser, editUser, deleteUser };
  return (
    <UsersMgrContext.Provider value={value}>
      {children}
    </UsersMgrContext.Provider>
  );
};

export type CreateUserPayload = Parameters<Api['users']['create']>[0];
interface UsersMgrContextType {
  users: WithPerms<WithRoles<User>>[];
  roles: WithPerms<Role>[];
  getRole: (RoleID: number) => WithPerms<Role> | undefined;
  createUser: (user: CreateUserPayload) => Promise<void>;
  editUser: (user: WithRoles<User>) => Promise<WithPerms<WithRoles<User>>>;
  deleteUser: (UserID: string | number) => Promise<User>;
}

export default function useUsersMgr() {
  const context = React.useContext(UsersMgrContext);
  if (!context) {
    throw new Error('useUsersMgr must be used within a UsersMgrProvider');
  }
  return context;
}
