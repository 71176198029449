import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  ZoomableGroup,
} from 'react-simple-maps';

const geoUrl = '/geo.json';

interface CountryData {
  key: string;
  value: number;
}

interface MapChartProps {
  data: CountryData[];
}

interface TooltipProps {
  country: string;
  value: number;
  x: number;
  y: number;
}

const Tooltip: React.FC<TooltipProps> = ({ country, value, x, y }) => (
  <div
    style={{
      position: 'absolute',
      left: `${x}px`,
      top: `${y}px`,
      padding: '5px',
      background: 'white',
      border: '1px solid #ccc',
      borderRadius: '3px',
      pointerEvents: 'none',
    }}
  >
    <strong>{country}</strong>: {value}
  </div>
);

const WorldMapChart: React.FC<MapChartProps> = ({ data }) => {
  const [tooltipContent, setTooltipContent] = useState<TooltipProps | null>(
    null,
  );
  const [position, setPosition] = useState<{
    coordinates: [number, number];
    zoom: number;
  }>({ coordinates: [0, 0], zoom: 1 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<Error | null>(null);
  const [parsedData, setParsedData] = useState<CountryData[]>([]);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setTooltipContent(prev =>
        prev
          ? {
              ...prev,
              x: event.clientX,
              y: event.clientY,
            }
          : null,
      );
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [tooltipContent]);

  useEffect(() => {
    try {
      // In case of multiple nationalities, we need to split
      const countryMap: { [key: string]: number } = {};
      data.forEach(({ key, value }) => {
        if (typeof key !== 'string') return;
        const countries = key.split(',');
        countries.forEach(country => {
          country = country.trim();
          if (countryMap[country]) {
            countryMap[country] += value;
          } else {
            countryMap[country] = value;
          }
        });
      });
      const parsed = Object.entries(countryMap).map(([key, value]) => ({
        key,
        value,
      }));
      setParsedData(parsed);
      setMaxValue(Math.max(...parsed.map(d => d.value)));
    } catch (err) {
      setError(err as Error);
    }
  }, [data]);

  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
  };

  const handleMoveEnd = (position: {
    coordinates: [number, number];
    zoom: number;
  }) => {
    setPosition(position);
  };

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
        An error occurred while rendering the world map chart
      </div>
    );
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 10, bottom: 10, zIndex: 1 }}>
        <Button onClick={handleZoomIn}>+</Button>
        <Button onClick={handleZoomOut}>-</Button>
      </div>
      <div
        ref={containerRef}
        style={{
          width: '70%',
          maxHeight: '200px',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        <ComposableMap>
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
          >
            <Sphere
              id='sphere'
              fill='transparent'
              stroke='#E4E5E6'
              strokeWidth={0}
            />
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const d = parsedData.find(s => s.key === geo.id);
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        d
                          ? `rgba(65, 105, 225, ${d.value / maxValue})`
                          : '#F5F4F6'
                      }
                      stroke='#D6D6DA'
                      style={{
                        default: { outline: 'none' },
                        hover: { outline: 'none', fill: '#F53' },
                        pressed: { outline: 'none' },
                      }}
                      onMouseEnter={event => {
                        const { name } = geo.properties;
                        const value = d ? d.value : 0;
                        setTooltipContent({
                          country: name,
                          value: value,
                          x: event.clientX,
                          y: event.clientY,
                        });
                      }}
                      onMouseLeave={() => {
                        setTooltipContent(null);
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        {tooltipContent && containerRef.current && (
          <Tooltip
            country={tooltipContent.country}
            value={tooltipContent.value}
            x={
              tooltipContent.x -
              containerRef.current.getBoundingClientRect().left
            }
            y={
              tooltipContent.y -
              containerRef.current.getBoundingClientRect().top
            }
          />
        )}
      </div>
    </>
  );
};

export default WorldMapChart;
