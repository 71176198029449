import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import IInsight from '../../../models/insights';
import { ManagedState } from '../../../utils/typing';

interface InsightCardsContextType {
  insights: IInsight[];
  selectedInsight: IInsight | null;
  setSelectedInsight: (insight: IInsight | null) => void;
  factors: string[];
  metrics: string[];
  selectedFactor: string;
  setSelectedFactor: (factor: string) => void;
  selectedMetric: string;
  setSelectedMetric: (metric: string) => void;
  updateInsight: (insight: IInsight) => void;
}

const InsightCardsContext = createContext<InsightCardsContextType | null>(null);

export type InsightProviderProps = ManagedState<'insights', IInsight[]>;

export const InsightCardsProvider: React.FC<
  PropsWithChildren<InsightProviderProps>
> = props => {
  const { children, insights, setInsights } = props;
  const [selectedInsightId, setSelectedInsightId] = useState<string | null>(
    null,
  );

  const factors = useMemo(() => {
    const factorSet = new Set<string>();
    insights.forEach(insight => {
      if (insight.factor && insight.factor.title) {
        factorSet.add(insight.factor.title);
      }
    });
    return Array.from(factorSet);
  }, [insights]);

  const metrics = useMemo(() => {
    const metricSet = new Set<string>();
    insights.forEach(insight => {
      if (insight.metric) {
        metricSet.add(insight.metric);
      }
    });
    return Array.from(metricSet);
  }, [insights]);

  const [selectedFactor, setSelectedFactor] = useState<string>('');
  const [selectedMetric, setSelectedMetric] = useState<string>('');

  const filteredInsights = useMemo(() => {
    return insights.filter(insight => {
      const factorMatch = selectedFactor
        ? insight.factor?.title?.includes(selectedFactor)
        : true;
      const metricMatch = selectedMetric
        ? insight.metric?.includes(selectedMetric)
        : true;
      return factorMatch && metricMatch;
    });
  }, [insights, selectedFactor, selectedMetric]);

  const selectedInsight = useMemo(() => {
    return insights.find(insight => insight._id === selectedInsightId) || null;
  }, [insights, selectedInsightId]);

  const setSelectedInsight = (insight: IInsight | null) =>
    setSelectedInsightId(insight ? insight._id : null);

  const updateInsight = useCallback(
    (insight: IInsight) => {
      setInsights(prev => prev.map(i => (i._id === insight._id ? insight : i)));
    },
    [setInsights],
  );

  const value: InsightCardsContextType = {
    insights: filteredInsights,
    selectedInsight,
    setSelectedInsight,
    factors,
    metrics,
    selectedFactor,
    setSelectedFactor,
    selectedMetric,
    setSelectedMetric,
    updateInsight,
  };

  return (
    <InsightCardsContext.Provider value={value}>
      {children}
    </InsightCardsContext.Provider>
  );
};

export default function useInsightCards() {
  const context = useContext(InsightCardsContext);
  if (!context)
    throw new Error('useInsights must be used within an InsightProvider');
  return context;
}
