import { Dispatch, SetStateAction } from 'react';

export type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>>;
      }[keyof T]
    : '';

export type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

export type Replace<T, K extends keyof T, V> = Omit<T, K> & {
  [P in K]: V;
};

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export function mergePartial<T>(base: T, partial: RecursivePartial<T>): T {
  return Object.entries(partial).reduce(
    (acc, curr) => {
      const [key, value] = curr as [keyof T, any];
      if (value && typeof value === 'object') {
        acc[key] = mergePartial(acc[key], value);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    deepClone(base || ({} as T)),
  );
}

// !! ONLY works with JSON object, not with class objects
export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export type ManagedState<Name extends string, T> = {
  [name in Name]: T;
} & {
  [set in `set${Capitalize<Name>}`]: Dispatch<SetStateAction<T>>;
};
export type ManagedStateFC<Name extends string, T> = React.FC<
  ManagedState<Name, T>
>;
export type ExtractKeys<T> = T extends readonly any[] ? T[number] : never;
