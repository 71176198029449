import React from 'react';
import { useNavigate } from 'react-router-dom';
import dam from '../../assets/dam.jpg';
import find from '../../assets/find.png';
import tiktokbanner from '../../assets/tiktok.png';

interface Experiment {
  id: number;
  url: string;
  title: string;
  description: string;
  imageSrc: string;
}

interface CardProps {
  experiment: Experiment;
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({ experiment, onClick }) => (
  <div
    style={{
      maxWidth: '300px',
      borderRadius: '4px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      margin: '1rem',
      cursor: 'pointer',
      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    }}
    onClick={onClick}
    onMouseEnter={e => {
      e.currentTarget.style.transform = 'scale(1.05)';
      e.currentTarget.style.boxShadow = '0 6px 8px rgba(0, 0, 0, 0.15)';
    }}
    onMouseLeave={e => {
      e.currentTarget.style.transform = 'scale(1)';
      e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
    }}
  >
    <img
      style={{
        width: '100%',
        height: '192px',
        objectFit: 'cover',
      }}
      src={experiment.imageSrc}
      alt={experiment.title}
    />
    <div
      style={{
        padding: '1.5rem 1rem',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          marginBottom: '0.5rem',
        }}
      >
        {experiment.title}
      </div>
      <p
        style={{
          color: '#4a5568',
          fontSize: '1rem',
        }}
      >
        {experiment.description}
      </p>
    </div>
  </div>
);

const ExperimentsBox: React.FC = () => {
  const navigate = useNavigate();

  const experiments: Experiment[] = [
    {
      id: 1,
      url: './Opera',
      title: 'Augmented Opera',
      description:
        'Augment our DAM with AI, to make it more intelligent and user-friendly.',
      imageSrc: dam,
    },
    {
      id: 2,
      url: './partnership-checker',
      title: 'Is it Paid ?',
      description: 'Check social medias for paid partnership.',
      imageSrc: tiktokbanner,
    },
    {
      id: 3,
      url: './Moments',
      title: 'Find a moment',
      description:
        'Find the exact moment in a video based on the user natural language query.',
      imageSrc: find,
    },
  ];

  const handleCardClick = (url: string) => {
    navigate(url);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      {experiments.map(experiment => (
        <Card
          key={experiment.id}
          experiment={experiment}
          onClick={() => handleCardClick(experiment.url)}
        />
      ))}
    </div>
  );
};

export default ExperimentsBox;
