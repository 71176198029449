import {
  Box,
  Modal,
  ModalProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import AllUsers from './All';
import { UsersMgrProvider } from './context';
import CreateUser from './Create';
import EditUser from './Edit';

const UsersManager: React.FC = () => {
  const navigate = useNavigate();
  return (
    <UsersMgrProvider>
      <Routes>
        <Route
          path='*'
          element={
            <Stack spacing={2} height='100%'>
              <Typography variant='h4'>Users Manager</Typography>
              <Box flexGrow={1} minHeight={0}>
                <AllUsers />
              </Box>
              <Outlet />
            </Stack>
          }
        >
          <Route
            path='create'
            element={
              <StandardModal open={true} onClose={() => navigate('.')}>
                <CreateUser onClose={() => navigate('.')} />
              </StandardModal>
            }
          />
          <Route
            path='edit/:id'
            element={
              <StandardModal open={true} onClose={() => navigate('.')}>
                <EditUser onClose={() => navigate('.')} />
              </StandardModal>
            }
          />
          <Route path='*' element={<Navigate to='.' />} />
        </Route>
      </Routes>
    </UsersMgrProvider>
  );
};
export default UsersManager;

export const StandardModal: React.FC<ModalProps> = props => {
  const { children, ...other } = props;
  return (
    <Modal {...other}>
      <StandardModalBox>{children}</StandardModalBox>
    </Modal>
  );
};

const StandardModalBox = styled(Stack)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  minWidth: '600px',
  background: 'white',
  padding: 20,
  borderRadius: 5,
  maxHeight: '80%',
  overflow: 'auto',
});
