import { Delete, Edit } from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { IVirtualColumn } from '../../models/virtualColumn';

interface VirtualFieldsTableProps {
  virtualFields: IVirtualColumn[];
  onEdit: (field: IVirtualColumn) => void;
  onDelete: (field: IVirtualColumn) => void;
}

const VirtualFieldsTable: React.FC<VirtualFieldsTableProps> = ({
  virtualFields,
  onEdit,
  onDelete,
}) => {
  const getFirstThreeLines = (formula: string) => {
    const lines = formula.split('\n');
    if (lines.length <= 3) return formula;
    return lines.slice(0, 3).join('\n') + '...';
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Formula</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {virtualFields.map(field => (
            <TableRow key={field._id}>
              <TableCell>{field.title}</TableCell>
              <TableCell>{field.name}</TableCell>
              <TableCell>{field.description}</TableCell>
              <TableCell>{getFirstThreeLines(field.formula || '')}</TableCell>
              <TableCell>
                <IconButton onClick={() => onEdit(field)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => onDelete(field)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VirtualFieldsTable;
