import papaparse, { LocalFile, ParseConfig, ParseResult } from 'papaparse';

type ParseOptions<T, TInput extends LocalFile = LocalFile> = ParseConfig<
  T,
  TInput
> & {
  requiredHeaders?: (keyof T)[];
  validateRow?: (row: T) => void | string;
};

export default function parseCsv<T, TInput extends LocalFile = LocalFile>(
  file: TInput,
  options: ParseOptions<T, TInput>,
) {
  const { complete, requiredHeaders = [], validateRow, ...rest } = options;
  return papaparse.parse<T, TInput>(file, {
    header: true,
    complete: (res, file) => {
      validateHeaders(res, requiredHeaders);
      if (!res.errors && validateRow) {
        res.data.forEach((row, index) => {
          const error = validateRow(row);
          if (error) {
            res.errors.push({
              type: 'RowValidation' as any,
              code: 'InvalidRow' as any,
              message: error,
              row: index,
            });
          }
        });
      }
      complete && complete(res, file);
    },
    ...rest,
  });
}

function validateHeaders<T>(res: ParseResult<T>, requiredHeaders: (keyof T)[]) {
  const { errors, meta } = res;
  const headers = meta.fields || [];
  const missingHeaders = requiredHeaders.filter(
    header => !headers.includes(header.toString()),
  );
  if (missingHeaders.length)
    errors.push({
      type: 'Header' as any,
      code: 'MissingHeaders' as any,
      message: `Missing headers: ${missingHeaders.join(', ')}`,
    });
}
