import { Box } from '@mui/material';
import { TagCloud } from 'react-tagcloud';
import { getColor } from '../../theme/colors';

export interface Tag {
  key: string;
  value: number;
}

interface CustomTagCloudProps {
  data: Tag[];
}

const CustomTagCloud: React.FC<CustomTagCloudProps> = ({ data }) => {
  const t = data.map(({ key, value }, i) => ({
    value: key,
    count: value,
    color: getColor(i),
  }));
  return (
    <Box width='100%'>
      <TagCloud tags={t} minSize={12} maxSize={30} />
    </Box>
  );
};
export default CustomTagCloud;
