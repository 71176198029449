// IconPicker.tsx
import React, { useState, useMemo } from 'react';
import { Box, IconButton, Modal, TextField, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type IconName = keyof typeof Icons;

interface IconPickerProps {
  open: boolean;
  onClose: () => void;
  onSelectIcon: (iconName: IconName) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({
  open,
  onClose,
  onSelectIcon,
}) => {
  const [iconFilter, setIconFilter] = useState('');

  const filteredIcons = useMemo(() => {
    return Object.keys(Icons)
      .filter(iconName =>
        iconName.toLowerCase().includes(iconFilter.toLowerCase()),
      )
      .slice(0, 100);
  }, [iconFilter]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <Typography variant='h6' component='h2' gutterBottom>
          Select an Icon
        </Typography>
        <TextField
          label='Filter icons'
          variant='outlined'
          fullWidth
          value={iconFilter}
          onChange={e => setIconFilter(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {filteredIcons.map(iconName => {
            const IconComponent = Icons[
              iconName as IconName
            ] as React.ComponentType<SvgIconProps>;
            return (
              <IconButton
                key={iconName}
                onClick={() => onSelectIcon(iconName as IconName)}
                color='warning'
                style={{ color: 'black !important' }}
              >
                <IconComponent />
              </IconButton>
            );
          })}
        </Box>
        {filteredIcons.length === 100 && (
          <Typography variant='body2' sx={{ mt: 2 }}>
            Showing first 100 results. Refine your search to see more.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export const DynamicIcon: React.FC<{
  iconName: string;
  style?: React.CSSProperties;
}> = ({ iconName, style }) => {
  const IconComponent = (Icons as any)[iconName];
  return IconComponent ? <IconComponent style={style} /> : null;
};

export default IconPicker;
