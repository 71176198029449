import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

interface SquareIconCardProps {
  icon: React.ElementType;
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

const SquareIconCard: React.FC<SquareIconCardProps> = ({
  icon: IconComponent,
  title,
  isSelected,
  onClick,
}) => (
  <Box
    display='flex'
    alignItems='center'
    textAlign='center'
    justifyContent='center'
    flexDirection='column'
    gap={1}
  >
    <Paper
      elevation={isSelected ? 8 : 1}
      sx={{
        width: 60,
        height: 60,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all 0.3s',
        backgroundColor: isSelected ? 'primary.light' : 'background.paper',
        '&:hover': {
          backgroundColor: 'primary.light',
        },
      }}
      onClick={onClick}
    >
      <IconComponent />
    </Paper>
    <Typography width={80} noWrap variant='caption'>
      {title}
    </Typography>
  </Box>
);

export default SquareIconCard;
