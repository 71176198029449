import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useAuthChecks } from '../../contexts/auth';
import { RoleName } from '../../models/users';
import { Login } from './Login';

interface AuthCheckoutProps {
  children?: ReactNode;
  role?: RoleName;
}
export const AuthCheckpoint: FC<AuthCheckoutProps> = props => {
  const { children, role } = props;
  const { isAuthenticated, hasRole } = useAuthChecks();
  console.log('isAuthenticated', isAuthenticated);
  if (!isAuthenticated) return <Login />;
  if (role && !hasRole(role)) return <NotAllowed role={role} />;
  return <>{children}</>;
};

const NotAllowed: FC<{ role: RoleName }> = ({ role }) => {
  return <Box>Not allowed, role required: {role}</Box>;
};
