import { capitalize } from '@mui/material';
import { ICreativeData } from '../../models/creativeData';
import {
  ICreativeDataStructure,
  IStructure,
  SType,
  UiType,
} from '../../models/creativeDataStructure';
import { typesMap } from '../../models/creativeDataStructure/typeMaps';
import { stringsSimilarity } from '../../utils/misc';

const buildInferCdsStructure =
  (samples: ICreativeData[]) => (cdName: string) => {
    const data = samples.map(s => s[cdName]).filter(Boolean);
    const cds: Partial<ICreativeDataStructure> = {
      name: cdName,
      title: inferTitle(cdName),
      structure: inferStructure(cdName, data[0]),
      description: inferDescription(cdName),
    };
    return cds;
  };
export default buildInferCdsStructure;

function inferTitle(cdName: string) {
  return cdName
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase());
}

const defaultUiTypes: {
  [key in SType]: UiType<key>;
} = {
  string: 'tag',
  number: 'id',
  boolean: 'boolean',
  date: 'date',
  object: 'unknown',
  array: 'simpleList',
};

const inferenceHelpers: {
  [key in SType]?: {
    [key: string]: UiType<key>;
  };
} = {
  string: {
    start: 'timestamp',
    end: 'timestamp',
  },
};

export function inferStructure(cdName: string, data: any): IStructure {
  const type = inferType(data);
  const uiType = inferUiType(type, cdName);
  switch (type) {
    case 'string':
    case 'number':
    case 'boolean':
    case 'date':
      return { type, uiType, showLabels: true };
    case 'array':
      return {
        type: type,
        uiType: uiType,
        showLabels: true,
        items: inferStructure(cdName, data[0]),
      };
    case 'object':
      return {
        type: 'object',
        uiType: uiType,
        showLabels: true,
      };
  }
}

function inferType(data: any): SType {
  const jsType = typeof data;
  switch (jsType) {
    case 'string':
    case 'number':
    case 'boolean':
      return jsType;
    case 'object':
      if (Array.isArray(data)) return 'array';
      return 'object';
    default:
      return 'string';
  }
}

function inferUiType<T extends SType>(type: T, cdName: string): UiType<T> {
  type SimilarityTuple = [number, UiType<T> | undefined];
  const possibleUiTypes = typesMap[type] as unknown as readonly UiType<T>[];
  const helper = inferenceHelpers[type]?.[cdName];
  if (helper) return helper;
  const exactMatch = possibleUiTypes.find(t => t === cdName.toLowerCase());
  if (exactMatch) return exactMatch;
  const partialMatch = possibleUiTypes
    .map(
      t => [stringsSimilarity(cdName.toLowerCase(), t), t] as SimilarityTuple,
    )
    .reduce((acc, c) => (c[0] > acc[0] ? c : acc), [
      0,
      undefined,
    ] as SimilarityTuple)[1];
  return partialMatch || defaultUiTypes[type];
}

function inferDescription(cdName: string) {
  const words = cdName.split(/(?=[A-Z])/).map(w => w.toLowerCase());
  return [capitalize(words[0]), ...words.slice(1)].join(' ');
}
