import {
  CheckCircleOutline,
  FileUploadOutlined,
  RemoveCircleOutline,
  SvgIconComponent,
} from '@mui/icons-material';
import { BoxProps, Stack, SvgIconProps, Typography } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface FileDropZoneProps {
  width?: string | number;
  height?: string | number;
  maxFileSize?: number | null;
  accept?: AcceptType[];
  onFileSelect: (file: File) => void;
  onError?: (error: string) => void;
  file?: File | null;
}

const FileDropzone: React.FC<FileDropZoneProps> = ({
  width = '100%',
  height = 200,
  maxFileSize = 10 * 1024 * 1024,
  accept = ['*/*'],
  onFileSelect,
  onError,
  file,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    // Clean up preview URL when component unmounts or file changes
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  useEffect(() => {
    if (file) {
      // Generate preview for image or video
      if (file.type.startsWith('image/')) {
        const url = URL.createObjectURL(file);
        setPreview(url);
      } else if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        const url = URL.createObjectURL(file);
        video.src = url;

        video.addEventListener('loadeddata', () => {
          // Create a canvas to capture the video thumbnail
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert canvas to data URL for preview
          const thumbnailUrl = canvas.toDataURL('image/jpeg');
          setPreview(thumbnailUrl);
          URL.revokeObjectURL(url);
        });

        video.addEventListener('error', () => {
          URL.revokeObjectURL(url);
          setPreview(null);
        });
      }
    } else {
      setPreview(null);
    }
  }, [file]);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && inputRef.current) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      inputRef.current.files = dataTransfer.files;
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        if (maxFileSize && file.size > maxFileSize) {
          const errorMsg = 'File is too large.';
          setError(errorMsg);
          onError?.(errorMsg);
        } else if (!isFileTypeAccepted(file, accept)) {
          const errorMsg = 'File type is not supported.';
          setError(errorMsg);
          onError?.(errorMsg);
        } else {
          setError(null);
          onFileSelect(file);
        }
      }
    },
    [maxFileSize, accept, onError, onFileSelect],
  );

  const mode: Mode = useMemo(() => {
    return isDragging
      ? 'dragging'
      : file
        ? 'success'
        : error
          ? 'error'
          : 'neutral';
  }, [file, error, isDragging]);

  const dropZoneColor = useMemo(() => dropZoneColors[mode], [mode]);
  const iconColor = useMemo(() => iconColors[mode], [mode]);
  const Icon = useMemo(() => icons[mode], [mode]);
  const text = useMemo(() => {
    if (isDragging) return 'Drop your file here';
    if (file) return file.name;
    if (error) return error;
    return 'Drop your file here';
  }, [file, error, isDragging]);

  return (
    <Stack
      sx={{ width, height, justifyContent: 'center', minWidth: '400px' }}
      spacing={1}
    >
      <Stack
        flexGrow={1}
        sx={{
          border: '2px dashed',
          borderColor: dropZoneColor,
          borderRadius: 2,
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          transition: 'border-color 0.3s ease',
          bgcolor: isDragging ? 'action.hover' : 'background.paper',
          position: 'relative',
          overflow: 'hidden',
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
        spacing={1}
      >
        <input
          type='file'
          accept={accept.join(',')}
          ref={inputRef}
          onChange={handleFileInput}
          style={{ display: 'none' }}
        />
        {preview ? (
          <img
            src={preview}
            alt='File preview'
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          />
        ) : (
          <>
            <Icon color={iconColor} fontSize='large' />
            <Typography variant='body1'>{text}</Typography>
          </>
        )}
      </Stack>
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <Typography variant='body2' color='textSecondary'>
          Supported format: {formatAccept(accept)}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          Max file size: {formatFileSize(maxFileSize)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FileDropzone;

type Mode = 'error' | 'success' | 'dragging' | 'neutral';

const dropZoneColors: Record<Mode, BoxProps['borderColor']> = {
  error: 'error.main',
  success: 'success.main',
  dragging: 'primary.main',
  neutral: 'grey.300',
};

const iconColors: Record<Mode, SvgIconProps['color']> = {
  error: 'error',
  success: 'success',
  dragging: 'primary',
  neutral: 'inherit',
};

const icons: Record<Mode, SvgIconComponent> = {
  error: RemoveCircleOutline,
  success: CheckCircleOutline,
  dragging: FileUploadOutlined,
  neutral: FileUploadOutlined,
};

const mimeTypes = {
  'image/*': ['image/jpeg', 'image/png', 'image/gif'],
  'video/*': ['video/mp4', 'video/avi', 'video/mov'],
  'audio/*': ['audio/mpeg', 'audio/wav'],
  'application/*': ['application/pdf', 'application/msword'],
  'text/*': ['text/plain', 'text/csv'],
} as const;
type Category = keyof typeof mimeTypes;
type MimeType = (typeof mimeTypes)[Category][number];
type AcceptType = MimeType | Category | '*/*';

function isFileTypeAccepted(file: File, accept: AcceptType[]): boolean {
  const mimeType = file.type;
  if (accept.includes('*/*')) return true;
  if (accept.includes(mimeType as MimeType)) return true;
  const mimeCategory = `${mimeType.split('/')[0]}/*` as Category;
  return accept.includes(mimeCategory);
}

function formatAccept(accept: AcceptType[]): string {
  if (accept.includes('*/*')) return 'any';
  return accept.join(', ');
}

function formatFileSize(size: number | null): string {
  if (size === null) return 'any';
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
}
